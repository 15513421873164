/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/* eslint-disable max-len */

/**
 * QRCodeCloseIcon icon
 *
 * @file QRCodeCloseIcon.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React from 'react';

const QRCodeCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48ZM29.618 15.5803L24 21.1983L18.382 15.5803C17.6083 14.8066 16.3539 14.8066 15.5803 15.5803C14.8066 16.3539 14.8066 17.6083 15.5803 18.382L21.1983 24L15.5803 29.618C14.8066 30.3917 14.8066 31.6461 15.5803 32.4197C16.3539 33.1934 17.6083 33.1934 18.382 32.4197L24 26.8017L29.618 32.4197C30.3917 33.1934 31.6461 33.1934 32.4197 32.4197C33.1934 31.6461 33.1934 30.3917 32.4197 29.618L26.8017 24L32.4197 18.382C33.1934 17.6083 33.1934 16.3539 32.4197 15.5803C31.6461 14.8066 30.3917 14.8066 29.618 15.5803Z" fill="#9A4341" />
  </svg>
);

export default QRCodeCloseIcon;
