/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * QRCodePrintMessage component
 *
 * @file QRCodePrintMessage.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  withStyles
} from '@material-ui/core';
import QRCode from 'react-qr-code';
import QRCodePrintMessageStyles from './styles/QRCodePrintMessageStyles';

const QRCodePrintMessage = ({
  classes,
  language,
  qrCode
}) => (
  <div className={classes.infodescriptionWrapper}>
    <Typography className={classes.dialogQRSubheader}>
      {language.getMessage('print.qrCode.header')}
    </Typography>
    <div className={classes.qrImageContainer}>
      {qrCode && <QRCode value={qrCode} size={200} />}
    </div>
    <div className={classes.qrMsgContainer}>
      <Typography className={classes.dialogQueMsg}>
        {language.getMessage('header.qrCode.dontHaveApp')}
      </Typography>
      <Typography className={classes.dialogFooterMsg}>
        {language.getMessage('print.qrCode.footer')}
      </Typography>
    </div>
    <div className={classes.dialogFooterAction}>
      <a aria-label="Download on Appstore" href="https://apps.apple.com/us/app/pearson-us-etexts/id1571362338" target="_blank" rel="noopener noreferrer">
        <img alt="Appstore" src="https://plus.pearson.com/auth-home/media/images/message-center/download_appstore.png" />
      </a>
      <a aria-label="Get it in Playstore" href="https://play.google.com/store/apps/details?id=com.pearson.plus" target="_blank" rel="noopener noreferrer">
        <img alt="Playstore" src="https://plus.pearson.com/auth-home/media/images/message-center/download_playstore.png" />
      </a>
    </div>
  </div>
);

QRCodePrintMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  qrCode: PropTypes.string.isRequired,
  language: PropTypes.object.isRequired
};

export default withStyles(QRCodePrintMessageStyles)(QRCodePrintMessage);
