/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2022 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { getSnapshot } from 'mobx-state-tree';
import CommonUtils from '../../../common/utils/CommonUtils';
import * as constants from '../../../common/constants';
import env from '../../../common/env';

/**
 * @author Mohamed yasar arabath M
 */

export default class TemplatesPlugin {
  constructor(context) {
    this.templatesContext = context;
    this.operations = ['eq', 'not', 'gte', 'lte', 'days_gte', 'days_lte'];
    this.ruleKeys = {};
    this.setDefaultSubscriptionContext();
    this.setDefaultBookContext();
    this.setDefaultAuthHomeContext();
    this.setDefaultNotificationContext();
  }

  /**
   * Reset/Setting the default context of book
   *
   */
  setDefaultSubscriptionContext = () => {
    this.ruleKeys.currentSubscription = {};
    // swap title banner default property
    this.ruleKeys.swapTitle = {}; // {"first14": {"allowSwap": true|false, expirationDate: <UTC TIMESTAMP>}
    // autoRenewal banner default property
    this.ruleKeys.autoRenewal = {}; // {"last21": {"autoRenewalCreditCard | autoRenewalBookStore": true|false, expirationDate: <UTC TIMESTAMP>}
    this.ruleKeys.subscriptionsStatus = ''; // ACTIVE, PASUED, EXPIRED, CANCELED
    this.ruleKeys.entitlementLevel = ''; // SINGLE, MUTLI etc.
    this.ruleKeys.subscriptionId = '';
  }

  /**
   * Reset/Setting the default context of book
   *
   */
  setDefaultBookContext = () => {
    this.ruleKeys.currentBook = {
      book: {},
      hasBannerDismissed: {
        switchTitle: false,
        autoRenewalCreditCard: false,
        autoRenewalBookStore: false,
        paused: false,
        expired: false
      },
      isActivePPlusBook: false,
      isPPlusBook: false
    };
  }

  /**
   * Sets default AuthHome context
   *
   */
  setDefaultAuthHomeContext = () => {
    this.ruleKeys.hasActiveBookStoreSubscription = false;
    this.ruleKeys.isAllActiveBookStoreHavePPlusEntitlements = false;
  }

  /**
   * Sets default Notification context
   *
   */
  setDefaultNotificationContext = () => {
    this.ruleKeys.userPreferences = {};
    this.ruleKeys.flags = {};
  }

  /**
   * Setting the actual context of current book for library banner
   *
   * @param {*} books
   */
  setCurrentBookContext = (book) => {
    this.setDefaultBookContext();
    // eslint-disable-next-line camelcase
    const { book_id, product_id } = book;
    // setting book information context
    this.ruleKeys.currentBook.book = book;
    // setting context the current books is active pplus book are not
    this.ruleKeys.currentBook.isActivePPlusBook = CommonUtils.isPPlusSubscriptionBook(book);
    // setting context the current books is expired or not
    this.ruleKeys.currentBook.isExpired = CommonUtils.isExpiredBook(book);
    // setting context the current book is pplus book or not
    this.ruleKeys.currentBook.isPPlusBook = CommonUtils.isPPlusSubscriptionBook(book, false);
    if (this.templatesContext.userPreferences) {
      const { dismissedAlertPreferences } = this.templatesContext.userPreferences;
      if (dismissedAlertPreferences && dismissedAlertPreferences.length > 0) {
        const dismissedBookBannerList = dismissedAlertPreferences.filter(({ bookId, productId }) => {
          const actualBookId = bookId || null;
          const actualProductId = productId || null;

          // eslint-disable-next-line camelcase
          return (actualBookId === book_id && actualProductId === product_id);
        });
        dismissedBookBannerList.forEach(({ type }) => {
          this.ruleKeys.currentBook.hasBannerDismissed[type] = true;
        });
      }
    }
  }

  /**
   * Setting context the current subscription based on current book for library banner
   *
   */
  setSubscriptionContext = () => {
    this.setDefaultSubscriptionContext();
    const { book } = this.ruleKeys.currentBook;
    const { user } = this.templatesContext.props;
    // eslint-disable-next-line camelcase
    const { subscription_id: subscriptionId } = CommonUtils.getProductEntitlementOfBook(book);
    const currentSubscription = CommonUtils.getMatchingGpsSubscriptionOfBook(subscriptionId, getSnapshot(user));
    this.ruleKeys.currentSubscription = currentSubscription;
    const subscriptionEndDate = this.getSubscriptionEndDate();
    // Setting the gps subscription's autoRenewal property of the current book in context
    this.ruleKeys.autoRenewal = this.setAutoRenewalRuleKeys(currentSubscription.autoRenewal || []);
    // Setting the gps subscription's swapTitle property of the current book in context
    this.ruleKeys.swapTitle = this.setSwapTitleRuleKeys(currentSubscription.swapTitle || []);
    this.ruleKeys.subscriptionsStatus = this.getSubscriptionStatus();
    this.ruleKeys.entitlementLevel = this.getEntitlementLevel();
    this.ruleKeys.subscriptionId = this.getSubscriptionId();
    this.ruleKeys.subscriptionEndDate = subscriptionEndDate && CommonUtils.formatDate(subscriptionEndDate);
    this.ruleKeys.PDPpageURL = this.getPDPpageURL();
  }

  /**
   * Sets AuthHome context
   *
   */
  setAuthHomeContext = () => {
    const { user, bookshelf } = this.templatesContext.props;
    const { books } = getSnapshot(bookshelf);

    this.ruleKeys.hasActiveBookStoreSubscription = CommonUtils.hasSubscriptionWithSource(
      user,
      constants.CHANNEL_PARTNER,
      constants.ACTIVE
    );
    this.ruleKeys.isAllActiveBookStoreHavePPlusEntitlements = CommonUtils.isAllActiveBookStoreHavePPlusEntitlements(
      getSnapshot(user),
      books
    );
  }

  /**
   * Sets Notification Context
   *
   */
  setNotificationContext = () => {
    this.ruleKeys.userPreferences = { ...this.templatesContext.userPreferences };
    this.ruleKeys.flags = { ...this.templatesContext.state.flags };
  }

  /**
   * To set auto renewal rule for last 21 days bookstore or credit card from GPS
   */

   setAutoRenewalRuleKeys = (autoRenewalList) => {
     let renderAutoRenew = {};

     if (autoRenewalList && autoRenewalList.length > 0) {
       autoRenewalList.forEach(({
         period, showRenewalAlert, type, expirationDate
       }) => {
         if (period) {
           const periodContext = period.toLowerCase();
           const showRenewal = !!showRenewalAlert;
           let autoRenewalObj = {};
           if (expirationDate) {
             autoRenewalObj = { ...autoRenewalObj, expirationDate: CommonUtils.formatDate(expirationDate) };
           }
           if (type) {
             switch (type.toLowerCase()) {
               case (constants.SUBSCRIPTION_BANNER.AUTORENEWALCREDITCARD.toLowerCase()):
                 autoRenewalObj = { ...autoRenewalObj, [constants.SUBSCRIPTION_BANNER.AUTORENEWALCREDITCARD]: showRenewal };
                 break;
               case (constants.SUBSCRIPTION_BANNER.AUTORENEWALBOOKSTORE.toLowerCase()):
                 autoRenewalObj = { ...autoRenewalObj, [constants.SUBSCRIPTION_BANNER.AUTORENEWALBOOKSTORE]: showRenewal };
                 break;
               default:
                 break;
             }
           }
           const autoRenewal = { [periodContext]: { ...autoRenewalObj } };
           renderAutoRenew = { ...renderAutoRenew, ...autoRenewal };
         }
       });
     }

     return renderAutoRenew;
   }

  /**
   * To set swap title rule either first14 or last14 along with count value from GPS
   */
  setSwapTitleRuleKeys = (swapTitleList) => {
    let renderSwapTitle = {};

    if (swapTitleList && swapTitleList.length > 0) {
      swapTitleList.forEach(({
        period, allowSwap, allowRenew, expirationDate
      }) => {
        if (period) {
          const periodContext = period.toLowerCase();
          let swapTitleObj = {};
          if (expirationDate) {
            swapTitleObj = { ...swapTitleObj, expirationDate: CommonUtils.formatDate(expirationDate) };
          }
          if (typeof allowSwap === 'boolean') {
            swapTitleObj = { ...swapTitleObj, allowSwap };
          }
          if (typeof allowRenew === 'boolean') {
            swapTitleObj = { ...swapTitleObj, allowRenew };
          }
          const swapTitle = { [periodContext]: { ...swapTitleObj } };
          renderSwapTitle = { ...renderSwapTitle, ...swapTitle };
        }
      });
    }

    return renderSwapTitle;
  }

  getSlides = (slides) => {
    const slidePositionArchived = [];
    const totalSlides = [];
    if (Array.isArray(slides)) {
      slides.forEach(({ slidePosition, ...slide }) => {
        if (slidePosition) {
          if (slidePositionArchived.indexOf(slidePosition) === -1) {
            if (this.checkRules(slide)) {
              slidePositionArchived.push(slidePosition);
              const renderSlide = this.matchContext(slide);
              totalSlides.push({ slidePosition, ...renderSlide });
            }
          }
        } else if (this.checkRules(slide)) {
          const renderSlide = this.matchContext(slide);
          totalSlides.push({ slidePosition, ...renderSlide });
        }
      });
    }

    return this.orderBySlidePos(totalSlides);
  }

  matchIdentifier = (text) => {
    const contextIdentifierMatches = text.match(/{context\.[^\\{]+}/g);
    let renderText = text;
    if (contextIdentifierMatches) {
      contextIdentifierMatches.forEach((contextIdentifier) => {
        const value = contextIdentifier.replace(/{|}|context\./g, '');
        renderText = renderText.replace(contextIdentifier, this.getRuleActualValue(value) || '');
      });
    }

    return renderText;
  }

  matchContext = (slide) => {
    const keys = ['title', 'description', 'action', 'slideAction'];
    keys.forEach((key) => {
      if (slide[key] && key === 'action' && slide[key].length > 0) {
        const actions = [];
        slide[key].forEach((action) => {
          if (action.launchURL) {
            const value = this.matchIdentifier(action.launchURL);
            actions.push({ ...action, launchURL: value });
          } else {
            actions.push({ ...action });
          }
        });
        slide[key] = actions;
      } else if (slide[key] && key === 'slideAction' && slide[key].launchURL) {
        const value = this.matchIdentifier(slide[key].launchURL);
        slide[key].launchURL = value;
      } else if (slide[key] && (key === 'title' || key === 'description')) {
        const value = this.matchIdentifier(slide[key]);
        slide[key] = value;
      }
    });

    return { ...slide };
  }

  // TODO: revisit the logic
  orderBySlidePos = (slides) => {
    const nonSlidesPosition = [];
    const slidePosition = [];
    slides.forEach((slide) => {
      if (slide.slidePosition) {
        slidePosition.push(slide);
      } else {
        nonSlidesPosition.push(slide);
      }
    });
    if (slidePosition.length > 0) {
      slidePosition.sort((a, b) => {
        const currentSlidePos = parseInt(a.slidePosition, 10);
        const nextSlidePos = parseInt(b.slidePosition, 10);
        return currentSlidePos - nextSlidePos;
      });
    }

    return [...slidePosition, ...nonSlidesPosition];
  }

  checkRules = (data) => {
    let eligible = true;
    if (data.rules) {
      eligible = data.rules.some(({ condition }) => this.isRuleMatch(condition));
    }

    return eligible;
  };

  isRuleMatch = (condition) => {
    let satisfied = false;
    if (condition) {
      satisfied = condition.every(({ name, value, operation }) => {
        let conditionCheck = false;
        const actualValue = this.getRuleActualValue(name);
        if (typeof actualValue !== 'undefined') {
          conditionCheck = this.operationCheck(actualValue, value, operation, Array.isArray(actualValue));
        }

        return conditionCheck;
      });
    }

    return satisfied;
  }

  getRuleActualValue = (name) => {
    let actualValue;

    if (name.indexOf('.') !== -1) {
      const nameList = name.split('.');
      actualValue = nameList.reduce((previousNode, currentNode) => {
        let traversal;
        if (typeof previousNode !== 'undefined' && previousNode.hasOwnProperty(currentNode)) {
          traversal = previousNode[currentNode];
        }

        return traversal;
      }, this.ruleKeys);
    } else {
      actualValue = this.ruleKeys[name];
    }

    if (typeof actualValue === 'undefined') {
      actualValue = null;
    }

    return actualValue;
  }

  operationCheck = (actualValue, value, operation, isArray = false) => {
    let conditionCheck = false;
    let noOfDays = 0;
    const [eq, not, gte, lte, daysGte, daysLte] = this.operations;
    switch (operation) {
      case eq:
        conditionCheck = isArray ? actualValue.includes(value) : actualValue === value;
        break;
      case not:
        conditionCheck = isArray ? !actualValue.includes(value) : actualValue !== value;
        break;
      case gte:
        conditionCheck = actualValue >= parseInt(value, 10);
        break;
      case lte:
        conditionCheck = actualValue <= parseInt(value, 10);
        break;
      case daysGte:
        noOfDays = CommonUtils.getNoOfDays(new Date(), actualValue);
        conditionCheck = (noOfDays >= 0 && noOfDays >= value);
        break;
      case daysLte:
        noOfDays = CommonUtils.getNoOfDays(new Date(), actualValue);
        conditionCheck = (noOfDays >= 0 && noOfDays <= value);
        break;
      default:
        break;
    }

    return conditionCheck;
  }

  gpsSubscriptionsFilter = (filterKey) => {
    const { currentSubscription } = this.ruleKeys;
    let gpsSubscription = {};
    if (!CommonUtils.isEmpty(currentSubscription)) {
      gpsSubscription = { ...currentSubscription };
    }

    return gpsSubscription[filterKey] || null;
  }

  /**
   * Method to get all Subscriptions stats for user
   *
   */
  getSubscriptionStatus = () => this.gpsSubscriptionsFilter('status')

  /**
   * Method to get EntitlementLevel for user
   *
   */
  getEntitlementLevel = () => this.gpsSubscriptionsFilter('entitlementLevel')

  /**
  * Method to get Subscription ID for user
  *
  */
  getSubscriptionId = () => this.gpsSubscriptionsFilter('subscriptionId')

  /**
  * Method to get Subscription ID for user
  *
  */
   getSubscriptionId = () => this.gpsSubscriptionsFilter('subscriptionId')

   /**
  * Method to get Subscription end date for user
  *
  */
   getSubscriptionEndDate = () => this.gpsSubscriptionsFilter('endDate')

   /**
    * To get PDP(PMC) product url
    *
    */
   getPDPpageURL = () => {
     const { book } = this.ruleKeys.currentBook;
     const pmcBaseUrl = `${env.BOOKSHELF_CONFIG.pmcSearch}/store/en-us/pearsonplus`;

     return book && book.ppId ? `${pmcBaseUrl}/p/${book.ppId}?identifierType=PPID` : `${pmcBaseUrl}/account`;
   }
}
