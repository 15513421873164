/* eslint-disable no-unused-expressions */
/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * More menu handler
 *
 * @file MoreMenuHandler.jsx
 * @author Saravanan Kandasamy
 */

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Framework, { Utils } from '@greenville/framework';
import { EventProvider } from '@aquila/core';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import axios from 'axios';
import PathUtils from './utils/PathUtils';
import ProductUtils from './utils/ProductUtils';
import * as constants from './constants';
import CommonUtils from './utils/CommonUtils';
import printIcon from './icons/printIcon';
import env from './env';

export default class MoreMenuHandler {
  constructor(context) {
    this.context = context;
    this.appConfig = {};
    this.isFromCourse = '';
    this.tpiValue = '';
    this.printPageWaterMarkOpacity = '0.3';
  }

  /**
  * Method to get menu items
  *
  */
  getMenuItems = (canadianProxy, courseId = null, appConfig, isFromCourse, bundleId, isOtpSent = false, isOtpSentPhone = false) => {
    this.appConfig = appConfig;
    this.isFromCourse = isFromCourse;
    this.devicesData = Framework.getStoreRegistry().getStore('device');
    this.tpiValue = PathUtils.getQueryParameterbyName('isTpi');
    const data = (this.context.props.course || this.context.props.product).getMenuData();
    const productInfo = this.isFromCourse ? this.context.props.course.getProduct() : this.context.props.product;
    const menuItem = [];
    let entitlementLevel = '';
    const {
      fullName, username, isImpersonatedId, mobileAccess
    } = Framework.getStoreRegistry().getStore('user');
    const isMojoUser = this.context.commonPlugin.isPPlusProduct();
    const { status, ...gpsSubscriptionOfProduct } = productInfo.getGpsSubscriptionOfProduct();
    const active = status === constants.ACTIVE ? gpsSubscriptionOfProduct : null;// TODO: need to refactor and give proper names
    this.BookType = isMojoUser ? constants.MOJO : constants.NONMOJO;
    entitlementLevel = active && active.entitlementTier;
    const language = Framework.getStoreRegistry().getStore('language');
    const year = new Date().getFullYear();
    const isPplusOrIAbookOrHEI = CommonUtils.isPplusOrIAbookOrHEI(productInfo.getBusinessModelCode());
    const menudivider = {
      type: 'menudivider'
    };
    const profiledivider = {
      type: 'profiledivider'
    };
    const userDetails = {
      id: constants.USER_DETAILS,
      fullName,
      username,
      type: constants.USER_DETAILS
    };
    if (!canadianProxy) {
      menuItem.push(userDetails, profiledivider);
    }
    const helpSupport = {
      id: constants.HELP_SUPPORT,
      buttonId: constants.HELP_SUPPORT_BTN_ID,
      ariaLabel: constants.HELP_SUPPORT_ARIA_LABEL,
      type: 'expand',
      text: <FormattedMessage {...language.getText('menu.HELP_SUPPORT')} />,
      list: []
    };
    const faqs = {
      type: 'link',
      text: <FormattedMessage {...language.getText('menu.FAQS')} />,
      id: constants.FAQS
    };
    const chatWithSupport = {
      type: 'link',
      text: (this.BookType === constants.MOJO ?
        <FormattedMessage {...language.getText('menu.CHAT_WITH_SUPPORT')} /> :
        <FormattedMessage {...language.getText('menu.CONTACT_SUPPORT')} />
      ),
      id: constants.CHAT_WITH_SUPPORT
    };
    const quickWalkthrough = {
      type: 'link',
      text: <FormattedMessage {...language.getText('menu.QUICK_WALKTHROUGH')} />,
      id: constants.QUICK_WALKTHROUGH
    };
    if (window.location.hostname.includes(constants.ETEXT)) {
      helpSupport.list.push(chatWithSupport);
      menuItem.push(helpSupport, menudivider);
    } else {
      this.BookType === constants.MOJO ? helpSupport.list.push(faqs, chatWithSupport, quickWalkthrough) : helpSupport.list.push(chatWithSupport, quickWalkthrough);
      menuItem.push(helpSupport, menudivider);
    }

    const otpcodes = {
      id: constants.OTPCODE,
      buttonId: constants.OTPCODE_BTN_ID,
      ariaLabel: constants.OTPCODE_ARIA_LABEL,
      type: 'expand',
      text: <FormattedMessage {...language.getText('menu.OTP_CODES')} />,
      list: [
        {
          type: 'link',
          text: <FormattedMessage {...language.getText('menu.LAUNCH_CODE')} />,
          id: constants.LAUNCH_CODE
        },
        {
          type: 'link',
          text: <FormattedMessage {...language.getText('menu.SEND_CODE')} />,
          id: constants.SEND_CODE,
          isOtpSent
        },
        {
          type: 'link',
          text: <FormattedMessage {...language.getText('menu.SEND_CODE_PHONE')} />,
          id: constants.SEND_CODE_PHONE,
          isOtpSentPhone
        }
      ]
    };

    if (isPplusOrIAbookOrHEI && isImpersonatedId) {
      menuItem.push(otpcodes, menudivider);
    }

    const handleDisconnect = (deviceId) => {
      Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
        type: 'disconnect',
        payload: { deviceId }
      });
    }

    const devices = {
      id: constants.DEVICES,
      buttonId: constants.DEVICE_BTN_ID,
      ariaLabel: constants.DEVICE_ARIA_LABEL,
      type: 'devices-expand',
      text: <FormattedMessage {...language.getText('menu.DEVICES')} />,
      list: [],
      deviceLableText: <FormattedMessage {...language.getText('menu.DEVICE_LABEL')} />,
      disconnectText: <FormattedMessage {...language.getText('menu.DEVICE_DISCONNECT')} />,
      isImpersonatedId,
      handleDisconnect
    };

    if (isPplusOrIAbookOrHEI) {
      const deviceLimit = this.devicesData.limit;
      const deviceWarningMessage = {
        id: 'devicewarning',
        type: 'warningmessage',
        text: <FormattedMessage {...language.getText('menu.DEVICE_WARNING_MESSAGE')} values={{ deviceLimit }} />
      };
      let devList = [];
      devList = this.addDevices(this.devicesData);
      devList && devList.push(deviceWarningMessage);
      devices.list = devList;
      devList && menuItem.push(devices, menudivider);
    }

    const legal = {
      id: constants.LEGAL,
      buttonId: constants.LEGAL_BTN_ID,
      ariaLabel: constants.LEGAL_ARIA_LABEL,
      type: 'expand',
      text: <FormattedMessage {...language.getText('menu.LEGAL')} />,
      list: [
        {
          type: 'link',
          text: <FormattedMessage {...language.getText('menu.TERMS_OF_USE')} />,
          id: constants.TERMS_OF_USE
        },
        {
          type: 'link',
          text: <FormattedMessage {...language.getText('menu.PRIVACY_NOTICE')} />,
          id: constants.PRIVACY_NOTICE
        }
      ]
    };
    if (window.location.hostname.includes(constants.PLUS)) {
      const guidelines =  {
        type: 'link',
        text: <FormattedMessage {...language.getText('menu.COMMUNITY_GUIDELINES')} />,
        id: constants.COMMUNITY_GUIDELINES
      }
      legal.list.push(guidelines);
    }
    const copyright = {
      id: 'copyright',
      type: 'nonlink',
      text: <FormattedMessage {...language.getText('menu.COPY_RIGHT')} values={{ year }} />
    };
    legal.list.push(copyright);

    const accessibility = {
      id: constants.ACCESSIBILITY,
      buttonId: constants.ACCESSIBILITY_BTN_ID,
      ariaLabel: constants.ACCESSIBILITY_ARIA_LABEL,
      type: 'expand',
      text: <FormattedMessage {...language.getText('menu.ACCESSIBILITY')} />,
      list: [
        {
          type: 'link',
          text: <FormattedMessage {...language.getText('menu.PEARSON_ACCESSIBILITY')} />,
          id: constants.PEARSON_ACCESSIBILITY
        }
      ]
    };
    if (isMojoUser) {
      const accessibilitySupport = {
        type: 'link',
        text: <FormattedMessage {...language.getText('menu.ACCESSIBILITY_SUPPORT')} />,
        id: constants.ACCESSIBILITY_SUPPORT
      };
      accessibility.list.push(accessibilitySupport);
    }

    const titeInfo = {
      id: constants.TITLE_INFO,
      buttonId: 'title expand',
      ariaLabel: constants.TITLE_INFO_ARIA_LABEL,
      type: 'expand',
      text: <FormattedMessage {...language.getText('menu.TITLE_INFO')} />,
      list: []
    };

    const displayCourse = {
      id: 'courseInfo',
      type: 'nonlink',
      text: `${data.courseName}, ${courseId}`
    };

    const foxitMenu = {
      type: 'nonlink',
      text: <FormattedMessage {...language.getText('menu.FOXIT')} />,
      value: constants.FOXIT
    };

    if (data.format === constants.CONTENT_TYPE_PDF && !isMojoUser) {
      titeInfo.list.push(foxitMenu);
    }

    if (courseId) {
      titeInfo.list.push(displayCourse);
    }

    const bookFormat = {
      id: 'bookformat',
      type: 'nonlink',
      text: ProductUtils.getProductDescription(data, entitlementLevel)
    };
    titeInfo.list.push(bookFormat);

    const signout = {
      type: 'signout',
      text: <FormattedMessage {...language.getText('menu.SIGN_OUT')} />,
      id: constants.SIGN_OUT
    };
    let appinfo = {};
    if (this.context.state.flags.enableQRcode) {
      appinfo = {
        id: constants.QR_CODE,
        type: constants.QR_CODE,
        getTheApp: <FormattedMessage {...language.getText('moremenu.downloadApp')} />,
        mojoAppInfo: mobileAccess ? language.getMessage('moremenu.downloadAppAlreadyDownload') : language.getMessage('moremenu.downloadAppNotDownload')
      };
    } else {
      appinfo = {
        id: constants.APP_INFO,
        type: 'appinfo',
        getTheApp: <FormattedMessage {...language.getText('menu.GET_THE_APP_DOWNLOAD')} />,
        mojoAppInfo: <FormattedMessage {...language.getText('menu.MOJO_APP_INFO')} />
      };
    }

    const isGhostAccount = (CommonUtils.isGhostAccount() || this.context.props.user.isTpi);

    if (!env.HIDE_APPINFO && window.location.hostname.includes(constants.PLUS)) {
      menuItem.push(legal, menudivider, accessibility, profiledivider, titeInfo);
      menuItem.push(profiledivider, appinfo);
      if (!isGhostAccount) {
        menuItem.push(profiledivider, signout);
      }
    }

    // print option
    const printOption = {
      id: constants.PRINT_PAGE,
      type: 'link',
      text: <FormattedMessage {...language.getText('menu.PRINT_PAGE')} />
    };
    const isPdfConfiguration = data.configuration && data.configuration.headerFeatures
      && data.configuration.headerFeatures.hasPrintLink;
    const isCongifuration = data.configuration && data.configuration.enableUserPrinting;

    if ((isPdfConfiguration)
      || isCongifuration
      || data.model === constants.CUSTOM_COLLECTIONS) {
      this.BookType === constants.MOJO ? menuItem.splice(canadianProxy ? 9 : 11, 0, menudivider, printOption) : menuItem.splice(canadianProxy ? 7 : 9, 0, menudivider, printOption);
    }

    return menuItem;
  };

  addDevices = (devicesData) => {
    const language = Framework.getStoreRegistry().getStore('language');
    const {
      isImpersonatedId
    } = Framework.getStoreRegistry().getStore('user');
    const deviceList = devicesData && devicesData.devices.filter(device => device.deviceId !== '');
    deviceList.sort((a, b) => new Date(a.lastLoginDate) - new Date(b.lastLoginDate));
    if (deviceList.length > 0) {
      let deviceListItems = [];
      if (isImpersonatedId) {
        deviceListItems = deviceList.map((value, index) => {
          return {
            type: 'nonlink',
            text: value.displayName,
            date: '',
            deviceId: value.deviceId,
            id: constants.DEVICES + index
          };
        });
      } else {
        deviceList.map((value, index) => {
          if (value.isNativeMobile && value.displayName) {
            deviceListItems.push({
              type: 'nonlink',
              text: value.displayName,
              date: '',
              deviceId: value.deviceId,
              id: constants.DEVICES + index
            });
          }
        });
        deviceListItems.push(
          {
            type: 'current',
            text: <FormattedMessage {...language.getText('menu.THIS_DEVICE')} />,
            date: '',
            deviceId: <FormattedMessage {...language.getText('menu.THIS_DEVICE')} />,
            id: `${constants.DEVICES}`
          }
        );
      }
      return deviceListItems;
    } else if (!isImpersonatedId) {
      const deviceListItems = [
        {
          type: 'current',
          text: <FormattedMessage {...language.getText('menu.THIS_DEVICE')} />,
          date: '',
          deviceId: <FormattedMessage {...language.getText('menu.THIS_DEVICE')} />,
          id: `${constants.DEVICES}0`
        }
      ];
      return deviceListItems;
    }
  }

  // Send account settings events
  handleEvents = (event) => {
    CommonUtils.initializeGTM();
    const customFields = {
      is_ghost_account: CommonUtils.isGhostAccount()
    };
    const accountSettingsEvent = {
      event: constants.ACCOUNT_SETTING,
      event_category: 'Account Settings',
      event_label: event,
      event_value: event,
      transaction_local_dt: CommonUtils.getLocalIsoTime(),
      user_local_event_dt: CommonUtils.getLocalIsoTime(),
      message_id: CommonUtils.getUUID(),
      event_unix_timestamp: new Date().getTime(),
      event_action: event,
      ...customFields
    };
    EventProvider.getEventManager().dispatch(accountSettingsEvent);
  }

  /**
  * Handles change events
  *
  * @param {*} event
  * @param {*} item
  */
  handleChange = (event, item) => {
    const language = Framework.getStoreRegistry().getStore('language');
    const mojoConfig = env.BOOKSHELF_CONFIG.more;
    switch (item.id) {
      case constants.PRINT_PAGE: {
        this.handleEvents(constants.PRINT_PAGE_EVENT);
        this.handlePrint();
        break;
      }
      case constants.FAQS: {
        this.handleEvents(constants.FAQS_EVENT);
        PathUtils.redirectToLink(this.BookType === constants.MOJO ?
          mojoConfig.MOJO_SUPPORT :
          language.getMessage(`link.FAQS_NONMOJO`));
        break;
      }
      case constants.CHAT_WITH_SUPPORT: {
        this.handleEvents(constants.CHAT_WITH_SUPPORT_EVENT);
        if (this.BookType === constants.MOJO) {
          window.location.href = `${env.CONTENT_HOST}/home?openChat=true`;
        } else {
          PathUtils.redirectToLink(language.getMessage('link.SUPPORT'));
        }
        break;
      }

      case constants.LAUNCH_CODE: {
        Framework.getEventManager().publish(constants.LAUNCH_CODE, () => {});
        break;
      }

      case constants.SEND_CODE: {
        Framework.getEventManager().publish(constants.OTPREQUESTED, {
          type:'email'
         });
        const deviceId = window.piSession && window.piSession.getDeviceId();
        Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
          type: 'generateOTP',
          data: {
            clientId: env.CLIENT_ID,
            deviceId
          }
        });
        break;
      }

      case constants.SEND_CODE_PHONE: {
        Framework.getEventManager().publish(constants.OTPREQUESTED, {
          type: 'text'
       });
        Framework.getEventManager().publish(constants.SEND_CODE_PHONE, {
          dataEvent: event.currentTarget
        });
        break;
      }

      case constants.TERMS_OF_USE: {
        this.handleEvents(constants.TERMS_OF_USE_EVENT);
        if (window.location.hostname.includes(constants.ETEXT)) {
          PathUtils.redirectToLink(language.getMessage('link.TOSURL'));
        } else {
          PathUtils.redirectToLink(this.BookType === constants.MOJO ?
            mojoConfig.MOJO_TERMS : language.getMessage(`link.TERMS_OF_USE_NONMOJO`));
        }
        break;
      }
      case constants.PRIVACY_NOTICE: {
        this.handleEvents(constants.PRIVACY_NOTICE_EVENT);
        if (window.location.hostname.includes(constants.ETEXT)) {
          PathUtils.redirectToLink(language.getMessage('link.PRIVACY'));
        } else {
          PathUtils.redirectToLink(this.BookType === constants.MOJO ?
            mojoConfig.MOJO_PRIVACY : language.getMessage(`link.PRIVACY_NOTICE_NONMOJO`));
        }
        break;
      }
      case constants.COMMUNITY_GUIDELINES: {
        PathUtils.redirectToLink(mojoConfig.COMMUNITY_GUIDELINES);
        break;
      }
      case constants.PEARSON_ACCESSIBILITY: {
        this.handleEvents(constants.PEARSON_ACCESSIBILITY_EVENT);
        PathUtils.redirectToLink(this.BookType === constants.MOJO ?
          mojoConfig.MOJO_PEARSON_ACCESSIBILITY : language.getMessage(`link.ACCESSIBILITY`));
        break;
      }
      case constants.APP_INFO: {
        this.handleEvents(constants.GET_THE_APP_EVENT);
        window.open(env.BOOKSHELF_CONFIG.more.MOJO_APP, '_blank');
        break;
      }
      case constants.HELP_SUPPORT: {
        this.handleEvents(constants.HELP_SUPPORT_EVENT);
        break;
      }
      case constants.DEVICES: {
        this.handleEvents(constants.DEVICES_EVENT);
        break;
      }
      case constants.LEGAL: {
        this.handleEvents(constants.LEGAL_EVENT);
        break;
      }
      case constants.ACCESSIBILITY: {
        this.handleEvents(constants.ACCESSIBILITY_EVENT);
        break;
      }
      case constants.ACCESSIBILITY_SUPPORT: {
        this.handleEvents(constants.ACCESSIBILITY_SUPPORT_EVENT);
        PathUtils.redirectToLink(this.BookType === constants.MOJO ?
          mojoConfig.MOJO_ACCESSIBILITY : language.getMessage(`link.ACCESSIBILITY_SUPPORT_NONMOJO`));
        break;
      }
      case constants.TITLE_INFO: {
        this.handleEvents(constants.TITLE_INFO_EVENT);
        break;
      }
      case constants.QR_CODE: {
        this.context.handleQrCodeClick(constants.QR_CODE_EVENT.PROFILE_DROPDOWN_FIELD);
        break;
      }
      case constants.SIGN_OUT: {
        this.handleEvents(constants.SIGN_OUT_EVENT);
        this.handleSignOut();
        break;
      }
      // no default
    }
  };

  /**
  * Handles sign out
  *
  */
  handleSignOut = () => {
    if (CommonUtils.getSessionStorge(constants.AI_AUTO_OPEN)) {
      CommonUtils.clearSessionStorge(constants.AI_AUTO_OPEN);
    }
    Framework.logout();
  };

  encodeSvg = (reactElement) => {
    const str = encodeURIComponent(ReactDOMServer.renderToStaticMarkup((reactElement)));
    const base64 = `data:image/svg+xml,${str}`;
    return base64;
  }

  /**
  * Method to handle print
  *
  */
  handlePrint = () => {
    const { email } = Framework.getStoreRegistry().getStore('user');
    const {
      course, product, asset, match
    } = this.context.props;
    const data = (course || product).getMenuData();
    const basePath = (course || product).getBasePath();
    const { pageId } = match.params;
    const isSPDF = course ? course.getProduct().getServerSideStatus() : product.getServerSideStatus();
    const bookId = course ? course.getProduct().bookID : product.bookID;
    let pageUri = '';

    Utils.treeWalk(asset, null, 'children', -1, (node) => {
      if (node.id === pageId) {
        pageUri = node.uri;
        return false; // false stops the iteration
      }
      return true; // we need to continue to walk the tree since we did not find a match
    });

    let secondPageUrl = '';
    const currentPageNo = sessionStorage.getItem('currentPageNo');
    if (isSPDF) {
      const isDoublePage = sessionStorage.getItem(`frame1_${bookId}_isDoublePageLayout`);
      /**
       * if double page layout enabled,
       * add left page url to the query param
       */
      if (isDoublePage === 'true') {
        secondPageUrl = `${window.foxitAssetURL}/pages/page${parseInt(currentPageNo, 10) + 1}?password=&formMode=true&accessToken=null`;
      }
    }
    /**
     * url - main page url in single page layout
     */
    const url = isSPDF ? `${window.foxitAssetURL}/pages/page${currentPageNo}?password=&formMode=true&accessToken=null` : `${basePath}/${pageUri}`;
    const pageData = { pageIndex: 0, scale: 2 };
    const imageEvent = { type: 'getPrintImg', data: pageData, success: this.printPage };

    if (data.format === constants.CONTENT_TYPE_PDF && !isSPDF) {
      window.WebPDF.Tool.getReaderApp().getDataControl().getPrintImg(imageEvent);
    } else {
      const printObj = {
        email,
        url: `${url}`,
        secondPageUrl: `${secondPageUrl}`
      }
      this.printPageNonPdf(printObj, data.format, isSPDF);
    }
  }

  printPageNonPdf = (printObj, format, isSPDF) => {
    const email = printObj.email.toUpperCase();
    const truncateData = email.length > 17 ? '...' : '';
    const dynamicEmail = `FOR THE PRIVATE USE OF ${email.substring(0, 17)}${truncateData}`;
    const printImage = this.encodeSvg(printIcon(dynamicEmail));
    const getHeadTag = window.document.getElementsByTagName('head');
    $(getHeadTag).append(`<style>@media print{@page { size:A4; margin-bottom: 0;}thead{display:table-header-group;}tfoot{display:table-footer-group;}tbody{display:table-row-group;}body{-webkit-print-color-adjust: exact !important;color-adjust: exact !important;}#watermark {display:none; background-image: url(${printImage}); background-position: center; background-repeat: no-repeat;background-size: 70%; font-size: 65pt;position: fixed;width: 100%;height: 100%;opacity: ${this.printPageWaterMarkOpacity};left: 0%;top:0%;}#divHeader {display:block;color: green;font-size: 15pt; position: fixed;xmargin: 0;z-index: 100;left: 0%;top:0%;}#divFooter {display:block;color: green;font-size: 15pt; position: fixed; xmargin: 0;z-index: 100;left: 0%;top:97%;} iframe-table{border:none !important;}} @media screen{thead{display:none;}tfoot{display:none;}tbody{display:table-row-group;}#watermark {display:block;color: #d0d0d0;background-image: url(${printImage});background-position: center; background-repeat: no-repeat;position: fixed;width: 100%;height: 100%;background-size: 70%; opacity: ${this.printPageWaterMarkOpacity};left: 0%;top:0%;}#divHeader {display:block;color: green;font-size: 15pt; position: fixed;xmargin: 0;z-index: 100;left: 0%;top:3%;}#divFooter {display:block;color: green;font-size: 15pt; position: fixed; xmargin: 0;z-index: 100;left: 0%;top:3%;}.iframe-table{border:none !important;}} </style>`);
    /**
     * getUrl is the main page of single page layout
     */
    const getUrl = printObj.url
    const type = printObj.type
    /**
     * secondPageUrl is right side page of the double page layout pdf
     */
    const secondPageUrl = printObj.secondPageUrl
    const contentType = type ? 'application/octet-stream' : 'text/plain';
    axios.get(getUrl, {
      headers: { 'Content-Type': contentType },
    }).then(response => response.data).then((dataResponse) => {
      const iframe = document.createElement('iframe');
      let data = dataResponse;
      iframe.id = 'printFrame';
      iframe.style.width = '100%';
      iframe.style.height = '100%';
      iframe.scrolling = 'no';
      iframe.style.overflow = 'hidden';
      iframe.style.border = '0';
      iframe.style.display = 'none';

      window.document.body.appendChild(iframe);
      const iframeObj = document.getElementById('printFrame');
      const iframeDoc = iframeObj.contentDocument;
      let style = `<style>@media print { #watermark {display:block; background-image: url(${printImage});background-position: center;background-repeat: no-repeat; background-size: 70%;position: fixed; width: 100%; height: 100%; opacity: ${this.printPageWaterMarkOpacity}; left: 0%; top:0%; text-align: center;}}</style>`;

      if (isSPDF) {
        style = `<style>@media print {html, body { height: 99%; } .spdfImage {height: 99%; transform : scale(0.80); transform-origin: 0 0;} #watermark {display:block; background-image: url(${printImage});background-position: center; background-repeat: no-repeat;position: fixed; width: 100%; height: 100%; opacity: ${this.printPageWaterMarkOpacity}; left: 0%; top:0%; text-align: center;}}</style>`;
      }
      const index = data.indexOf('<head');
      /**
       * to check the book formats
       */
      if (index === -1) {
        let secondPage = '';
        /**
         * Adding second page to print preview
         */
        if (secondPageUrl) {
          secondPage = `<img class ='spdfImage' src=${secondPageUrl}></img>`;
        }
        data = `<html><head>${style}<style>.contentPlaceholder{background-color:#efefef;padding:30px;border-width: 1px;border-color: #999999;font-size: 15pt;color:#b3b3b3;text-align: center;display: table-cell;vertical-align: middle;}@media print{#pagelogo{display:block;}.iframe-table{border:none !important;} #watermark p{ line-height: 1.2;}body{-webkit-print-color-adjust: exact !important;color-adjust: exact !important;} thead{display:table-header-group;text-align:left;}tfoot{display:table-footer-group;text-align:left;}tbody{display:table-row-group;}}@media screen{#pagelogo{display:none;}.iframe-table{border:none !important;}thead{}tfoot{display:none;}#watermark {display:block;color: #d0d0d0; background-image: url(${printImage});background-position: center; background-repeat: no-repeat; background-size: 70%;position: fixed;width: 100%;height: 100%; opacity: ${this.printPageWaterMarkOpacity};left: 0%;top:0%;}} </style></head><body><img class="spdfImage" src="${getUrl}"></img>${secondPage}</body></html>`;
      } else {
        data = `${data.slice(0, index)}
            <base href="${getUrl}" />
             ${data.slice(index)}`;
        data = data.replace('</head>', `${style}<style>.contentPlaceholder{background-color:#efefef;padding:30px;border-width: 1px;border-color: #999999;font-size: 15pt;color:#b3b3b3;text-align: center;display: table-cell;vertical-align: middle;}@media print{#pagelogo{display:block;}.iframe-table{border:none !important;} body{-webkit-print-color-adjust: exact !important;color-adjust: exact !important;} #watermark p{ line-height: 1.2;} thead{display:table-header-group;text-align:left;}tfoot{display:table-footer-group;text-align:left;}tbody{display:table-row-group;}}@media screen{#pagelogo{display:none;}.iframe-table{border:none !important;}thead{}tfoot{display:none;}#watermark {display:block;background-image: url(${printImage});background-position: center; background-repeat: no-repeat; position: fixed;background-size: 70%; width: 100%;height: 100%; opacity: 0.6;left:0%;top:0%;}} img{max-width: 100%;}</style></head>`);
      }
      iframeDoc.open();
      iframeDoc.write(data);
      iframeDoc.close();

      setTimeout(() => {
        iframeObj.style.height = `${iframeObj.contentWindow.document.body.scrollHeight + 30}px`;
        $('#printFrame').contents().find('body').append('<div id="watermark"><p></p></div>');
        $('#printFrame').contents().find('body').find('[data-offlinesupport="no"]')
          .each(function () {
            const fallback = $(this).find('.fallback');
            if (fallback.children().length > 0) {
              $(this).replaceWith(fallback.show());
            } else if (!$(this).hasClass('ulink')) {
              $(this).replaceWith('<div class="contentPlaceholder" > This content is unavailable when printing </div>').show();
            }
          });

        if (format === 'CITE') {
          $('#printFrame').contents().find('body').find('audio, video')
            .each(function () {
              if (!$(this).hasClass('ulink')) {
                $(this).replaceWith('<div class="contentPlaceholder" > This content is unavailable when printing </div>').show();
              }
            });
        }
        document.getElementById('printFrame') && document.getElementById('printFrame').contentWindow.print();
      }, 2000);
    }).catch(() => {
      /* console.log(err); To check error */
    });
  }

  /**
  * Method for printing current page
  *
  * @param {Object} pageData
  */
  printPage = (pageData) => {
    const { fullName, email } = Framework.getStoreRegistry().getStore('user');
    const truncateData = email.length > 17 ? '...' : '';
    const dynamicEmail = `FOR THE PRIVATE USE OF ${email.substring(0, 17)}${truncateData}`;
    const printImage = this.encodeSvg(printIcon(dynamicEmail));
    const data = (this.context.props.course || this.context.props.product).getMenuData();
    const printWithFooter = (data.configuration
      && data.configuration.generalFeatures && data.configuration.generalFeatures.printWithFooter);
    const printWithWatermark = (data.configuration
      && data.configuration.generalFeatures && data.configuration.generalFeatures.printWithWatermark);
    /* global Blob */
    const blob = new Blob([pageData.imageData], { type: 'image/png' });
    const pageSrc = URL.createObjectURL(blob);
    const date = new Date();
    const currentDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    const year = date.getFullYear();
    const userData = {
      fullName,
      currentDate,
      email,
      year
    };
    const language = Framework.getStoreRegistry().getStore('language');
    let copyrightViolationMsg = language.getMessage('print.FOOTER_MESSAGE');
    let printWatermark = language.getMessage('print.WATERMARK');

    Object.keys(userData).forEach((key) => {
      copyrightViolationMsg = copyrightViolationMsg.split(`{${key}}`).join(userData[key]);
      printWatermark = printWatermark.split(`{${key}}`).join(userData[key]);
    });
    const printFrame = document.createElement('iframe');

    printFrame.id = 'printFrame';
    printFrame.tabIndex = '-1';
    printFrame.style.width = '0px';
    printFrame.style.height = '0px';
    document.body.appendChild(printFrame);
    let frameContentCss = `#watermark { 
       display: none;
       background-image: url(${printImage});
       background-position: center;
       background-repeat: no-repeat;
       background-size: 70%; 
       font-size: 65pt;
       position: fixed;
       width: 100%;
       height: 100%;
       opacity: ${this.printPageWaterMarkOpacity};
       left: 0%;
       top:0%;
       -webkit-print-color-adjust: exact !important;
       color-adjust: exact !important;
    }#footer {
      bottom: 0;
      position: absolute;
      display: none;
      font-size: 14px
    }`;

    if (copyrightViolationMsg && printWatermark) {
      frameContentCss += `@media print {
        @page { size: A4; page-break-after: avoid; margin-bottom: 0;}
        img { max-height: 29cm; max-width: 21cm; margin: 0;}
        #footer { display: block; } #watermark{ display: block; }}`;
    } else if (printWithFooter && !printWithWatermark) {
      frameContentCss += `@media print {
        @page { size: A4; page-break-after: avoid; margin-bottom: 0;}
        img { max-height: 29cm; max-width: 21cm; margin:0;}
        #footer { display: block; }}`;
    } else if (!printWithFooter) {
      if (printWithWatermark) {
        frameContentCss += `@media print {
          @page { size: A4; page-break-after: avoid; margin-bottom: 0;}
          img { max-height: 29cm; max-width: 21cm; margin: 0;}
          #watermark{ display: block; }}`;
      } else {
        frameContentCss += `@media print {
          @page { size: A4; page-break-after: avoid; margin-bottom: 0;}
          img{ max-height: 29cm; max-width: 21cm; margin: 0;}}`;
      }
    }
    printFrame.contentWindow.document.open();
    printFrame.contentWindow.document.write(`<style type="text/css">${frameContentCss}</style>`);
    printFrame.contentWindow.document.write(`<div><img src="${pageSrc}" onload="window.print();">
      <div id=watermark></div>`);
    printFrame.contentWindow.document.close();
    window.onafterprint = () => {
      document.body.removeChild(document.getElementById('printFrame'));
    };
  }
}
