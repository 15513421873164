/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Onboarding
 *
 * @file Onboarding.jsx
 * @author Mohamed yasar arabath M
 */

import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog } from '@material-ui/core';
import CommonUtils from '../../common/utils/CommonUtils';
import OnboardStyles from './styles/OnboardStyles';
import * as constants from '../../common/constants';

const Marquee = CommonUtils.lazyWithPreload(() => import('@authhome/marquee')
  .then(module => ({ default: module.Marquee })));

const Onboarding = ({
  openOnBoard, onboardSlideData, gotoSlide, callbacks
}) => {
  const classes = OnboardStyles()();
  const {
    onButtonClick
  } = callbacks;

  return (
    <Suspense fallback={<Fragment />}>
      <Dialog
        scroll="body"
        open={openOnBoard}
        maxWidth={false}
        classes={{ root: classes.dialogRoot, paper: classes.paperRoot }}
        id={constants.ONE_READER_MODAL_ONBOARD_POPUP}
      >
        <Box classes={{ root: classes.root }}>
          <Marquee
            slides={onboardSlideData}
            onButtonClick={onButtonClick}
            gotoSlide={gotoSlide}
          />
        </Box>
      </Dialog>
    </Suspense>
  );
};

Onboarding.defaultProps = {
  openOnBoard: false,
  onboardSlideData: [],
  gotoSlide: 0
};

Onboarding.propTypes = {
  openOnBoard: PropTypes.bool,
  onboardSlideData: PropTypes.array,
  gotoSlide: PropTypes.number,
  callbacks: PropTypes.object.isRequired
};

export default Onboarding;
