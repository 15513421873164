/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
*  Copyright © 2022 Pearson Education, Inc.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Pearson Education, Inc.  The intellectual and technical concepts contained
* herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
* patent applications, and are protected by trade secret or copyright law.
* Dissemination of this information, reproduction of this material, and copying or distribution of this software
* is strictly forbidden unless prior written permission is obtained
* from Pearson Education, Inc.
* */

/**
 * QR code popup style
 *
 * @file QRCodeStyles.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import mixins from '../../../../assets/styles/mixins.scss';

const QRCodeStyles = () => ({
 dialogPaper: {
    alignItems: 'center',
    padding: 24,
    borderRadius: 16,
    boxShadow: '0px 0px 32px 0px #020917',
    background: mixins.$whiteFont,
    '&:focus-visible': {
      background: mixins.qrWhiteLightFont,
      outline: 'none',
      border: `2px dashed ${mixins.swatchDarkFont}`,
      boxShadow: `0px 0px 0px 2px ${mixins.qrWhiteLightFont}`
    },
    '@media screen and (min-width: 768px)': {
      width: 480,
      minHeight: 648
    },
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      minWidth: 344,
      minHeight: 612,
      padding: 16
      // gap: 16
    }
  },
  dialogContentContainer: {
    order: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    marginTop: 32,
    padding: '0px !important',
    overflowY: 'unset',
    '@media screen and (min-width: 768px)': {
      width: 432,
      maxHeight: 456
    },
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      width: 312,
      maxHeight: 468,
      marginTop: 16
    }
  },
  dialogtitle: {
    padding: '9px 0px',
    height: 40
  },
  dialogheader: {
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize24,
    lineHeight: mixins.lineHeight28,
    color: mixins.darkThemeColor,
    letterSpacing: '0.4px',
    textAlign: 'left',

    '@media screen and (min-width: 768px)': {
      width: 368
    },
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      width: 248,
      fontSize: mixins.fontSize20,
      lineHeight: mixins.lineHeight24
    }
  },
  dialogAction: {
    padding: 0,
    margin: 0,
    width: 40,
    height: 40
  },
  closeIcon: {
    width: 44,
    height: 44
  },
  qrimage: {
    width: 145,
    height: 146
  },
  dialogQRSubheader: {
    height: 48,
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize20,
    lineHeight: mixins.lineHeight24,
    textAlign: 'center',
    letterSpacing: '0.4px',
    color: mixins.darkThemeColor,
    wordBreak: 'break-word',
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      width: 312,
      height: 72
    }
  },
  infodescriptionWrapper: {
    height: 347,
    gap: 32,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      gap: 16
    }
  },
  qrImageContainer: {
    display: 'flex',
    padding: 32,
    gap: 32,
    borderRadius: 12,
    border: '2px solid #C7CCD6',
    '@media screen and (min-width: 768px)': {
    }
  },
  qrMsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '@media screen and (min-width: 768px)': {
      width: 432,
      minHeight: 80
    }
  },
  dialogQueMsg: {
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize18,
    lineHeight: mixins.lineHeight24,
    textAlign: 'center',
    letterSpacing: '0.24px',
    color: mixins.darkThemeColor,
    wordBreak: 'break-word',
    '@media screen and (min-width: 768px)': {

    }
  },
  dialogFooterMsg: {
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight400,
    fontSize: mixins.fontSize16,
    lineHeight: mixins.lineHeight20,
    textAlign: 'center',
    letterSpacing: '0.24px',
    color: mixins.darkThemeColor,
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    overflow: 'hidden'
  },
  dialogFooterAction: {
    display: 'flex',
    justifyContent: 'center',
    width: 432,
    height: 40,
    gap: 8,
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      width: 312,
      height: 40
    }
  },
  tooltip: {
    fontFamily: mixins.fontFamilyTT,
    backgroundColor: mixins.whiteLightFont,
    color: mixins.darkThemeColor,
    fontStyle: 'normal',
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize14,
    lineHeight: mixins.fontSize24,
    width: 'max-content !important'
  },
  tooltipArrow: {
    color: mixins.whiteLightFont
  },
  popper: {
    '&[x-out-of-boundaries]': {
      display: 'none'
    }
  },
  dialogTitleInfoContanier: {
    order: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: 24,

    '@media screen and (min-width: 768px)': {
      width: 432,
      height: 40
    },
    '@media screen and (min-width: 360px) and (max-width: 767px)': {
      width: 312,
      height: 40
    }
  },
  dialogErrorMsg: {
    display: 'flex',
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize20,
    lineHeight: mixins.lineHeight24,
    textAlign: 'center',
    letterSpacing: '0.4px',
    color: mixins.qrErrorMsg,
    wordBreak: 'break-word',
    width: 232,
    justifyContent: 'center',
    alignItems: 'center'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    minWidth: 200
  },
  qrErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    gap: 16,
    borderRadius: 12,
    border: `2px solid ${mixins.qrErrorMsg}`,
    minHeight: 232
  },
  closeContainer: {
    padding: '6px 24px 6px 24px',
    border: `2px solid ${mixins.swatchDark800}`,
    borderRadius: 40,
    background: mixins.swatchDark800,
    width: 'fit-content',
    cursor: 'pointer',
    '& span': {
      minWidth: 'unset'
    },
    '&:hover': {
      background: mixins.darkBgBorder,
      border: `2px solid ${mixins.darkBgBorder}`
    },
    '&:focus-visible': {
      background: mixins.darkBgBorder,
      border: `2px dashed ${mixins.greyColor}`,
      boxShadow: `0px 0px 0px 2px ${mixins.darkBgBorder}`,
      '&::after': {
        border: 'none'
      }
    }
  },
  closeButton: {
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize18,
    lineHeight: mixins.lineHeight24,
    letterSpacing: '0.4px',
    color: mixins.whiteFont
  }
});
export default QRCodeStyles;
