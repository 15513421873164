/*
 *  PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
/* eslint-disable max-len */

/**
 * NotificationCloseIcon
 *
 * @file NotificationCloseIcon.jsx
 * @author Mohamed yasar arabath M
 */

import React from 'react';

const NotificationCloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 6.75478L10.4969 4.25789C10.8407 3.91404 11.3982 3.91404 11.7421 4.25789C12.086 4.60175 12.086 5.15926 11.7421 5.50311L9.24522 8L11.7421 10.4969C12.086 10.8407 12.086 11.3982 11.7421 11.7421C11.3982 12.086 10.8407 12.086 10.4969 11.7421L8 9.24522L5.50311 11.7421C5.15926 12.086 4.60175 12.086 4.25789 11.7421C3.91404 11.3982 3.91404 10.8407 4.25789 10.4969L6.75478 8L4.25789 5.50311C3.91404 5.15926 3.91404 4.60175 4.25789 4.25789C4.60175 3.91404 5.15926 3.91404 5.50311 4.25789L8 6.75478Z" fill="currentColor" />
  </svg>
);

export default NotificationCloseIcon;
