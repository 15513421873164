/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * MLM home file
 *
 * @file index.js
 * @author Gladson Samuel S
 */

import React from 'react';
import Framework from '@greenville/framework';
import { AUTHORIZATION } from '../../common/constants';
import env from '../../common/env';
import MLMAuthHomeContainer from './MLMAuthHomeContainer';

Framework.getClientManager().add({
  name: 'berlin',
  authHeader: AUTHORIZATION,
  timeout: env.MARIN_API_TIMEOUT,
  baseUrl: env.BERLIN.STREAM_BASE_URL,
  tokenPrefix: 'Bearer ',
  addCorrelationId: false,
  headers: {
    'x-berlin-tenant-id': env.BERLIN.TENANT_ID,
    'x-berlin-tenant-key': env.BERLIN.TENANT_KEY
  }
});

export default {
  path: [
    '/home2',
    '/home2/:mlmcontextencoded'
  ],
  exact: true,
  services: [],
  stores: [],
  render: props => (
    <MLMAuthHomeContainer {...props} />
  )
};
