/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Translation file for Polish language
 *
 * @file pl.js
 * @author Manimaran.S
 */

const pl = {
  'hero.ctanewtext': 'Open Study & Exam Prep',
  'hero.welcomeToP+': 'Welcome to Pearson+',
  'hero.etextfeaturestitle': 'Find study materials in your eTextbook',
  'hero.etextfeaturesdescription': 'This eTextbook doesn’t have an exact Study & Exam Prep topic match. Study with flashcards and more right in your eTexbook.',
  'aichat.title1': 'Get AI-powered help on an eTextbook topic.',
  'aichat.chatDesc1': 'Type a question to start',
  'aichat.title2': 'Get AI-powered help on an eTextbook topic.',
  'aichat.chatDesc2': 'Type a question to start',
  'subscriptiontext.isSubscribed': 'Part of your subscription',
  'subscriptiontext.nonSubscriber': 'Upgrade for full access',
  'topicConatiner.otherHeader': 'Related practice and video lessons',
  'topicConatiner.exactLiteHeader': 'Related practice and concept explanations',
  'authHome.h1': 'Authenticated Home',
  'moremenu.downloadApp': 'Download the app',
  'moremenu.downloadAppAlreadyDownload': '%Click here%, aby pobrać aplikację na inne urządzenie i zalogować się automatycznie lub użyć unikalnego kodu QR do bezpiecznego zalogowania się w aplikacji, którą już zainstalowałeś.',
  'moremenu.downloadAppNotDownload': '%Click here%, aby pobrać aplikację i bezpiecznie zalogować się za pomocą unikalnego kodu QR.',
  'channel.bundleTitle': 'Uzyskaj dostęp do pakietu do nauki i przygotowania do egzaminu dla innych kursów',
  'channel.noBundletitle': 'Wykorzystaj pakiet dostępu do nauki i przygotowania do egzaminu dla potrzeb innych kursów',
  'onboard.next': 'Dalej',
  'onboard.studyPlan': 'Utworzono indywidualny plan nauki!',
  'onboard.addCourseAnother': 'Czy chcesz dodać kolejny kurs?',
  'onboard.purchase': 'Muszę kupić e-Textbook Pearson',
  'onboard.addCourseWithoutEtext': 'Dodaj kurs bez e-Textbook',
  'onboard.addCourseWithoutEtextBundle': 'Dodaj kurs za pomocą funkcji dostępu do nauki i przygotowania do egzaminu',
  'onboard.doYouWantOtherCourse': 'Czy chcesz dodać kolejny kurs?',
  'onboard.mayBeLater': 'Może później',
  'onboard.close': 'Close',
  'course.addTitle': 'Dodaj kurs',
  'bookshelf.TITLE': 'Moja półka na książki',
  'common.LOGOUT': 'Wyloguj',
  'common.GO_HOME': 'Idź do Strony głównej',
  'common.PURCHASE': 'Zakup',
  'courses.WELCOME': 'Witaj {userName}, witaj na swoim kursie {courseName}!',
  'courses.action.SHOW_ASSIGNMENTS': 'Pokaż zadania',
  'courses.toc.PUBLISH_SUCCESS': 'Sukces!',
  'courses.toc.PUBLISH_MESSAGE': 'Studenci zobaczą Twoje zmiany, gdy następnym razem otworzą treść.',
  'errors.GENERIC_TITLE': 'You don\'t have access right now',
  'errors.GENERIC_DESC': 'First, refresh or clear your cache. If that doesn’t work, contact Pearson ',
  'errors.GENERIC_LINK': 'support.',
  'errors.GENERIC_CODE': 'Error code: {code}',
  'errors.SUBSCRIPTION_GENERIC_TITLE': 'Nie znaleziono subskrypcji',
  'errors.SUBSCRIPTION_GENERIC_DESC': `Twoje konto nie obejmuje dostępu do tego tytułu. Jeśli uważasz, 
    że otrzymałeś ten komunikat przez pomyłkę, skontaktuj się z pomocą techniczną Pearson`,
  'errors.SUBSCRIPTION_REGISTRATION_TITLE': 'Nie znaleziono subskrypcji',
  'errors.SUBSCRIPTION_REGISTRATION_DESC': `Twoja subskrypcja nie obejmuje dostępu do aplikacji eText, 
    jednak możesz ją teraz zasubskrybować za pomocą karty kredytowej.`,
  'foxit.ABOUT': 'O',
  'foxit.COPY_RIGHT': 'PDF Reader obsługiwany przez Foxit. Prawa autorskie (C) 2003-2019 Foxit Software Incorporate',
  'link.ACCESSIBILITY': 'https://www.pearson.com/us/accessibility.html',
  'link.PRIVACY': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonPrivacyPolicy.html',
  // eslint-disable-next-line max-len
  'link.SUPPORT': 'https://support.pearson.com/getsupport/s/?_ga=2.116082310.1845539610.1557867919-NimtqV7BcT00BM9xOXCQUFJYwF3RzEIk',
  'link.TOSURL': 'https://pi.pearsoned.com/v1/piapi/login/static/html/PearsonEULA.html',
  'menu.GET_THE_APP_DOWNLOAD': 'Pobierz aplikację mobilną',
  'menu.CONTACT_SUPPORT': 'Kontakt z pomocą techniczną',
  'menu.ABOUT': 'O',
  'menu.FOXIT': 'Obsługiwane przez Foxit',
  'menu.TERMS_OF_USE': 'Warunki korzystania',
  'menu.PRIVACY_POLICY': 'Polityka prywatności',
  'menu.SIGN_OUT': 'Wyloguj',
  'menu.PRINT_PAGE': 'Drukuj stronę',
  'menu.GET_LOOSE_LEAF': 'Zdobądź luźną kartę',
  'menu.AVAILABLE': 'Również dostępne',
  'menu.PRINT_VERSION': 'Potrzebujesz drukowaną wersję?',
  'menu.COPY_RIGHT': 'Prawo autorskie © {year} Pearson Education Inc. Wszystkie prawa zastrzeżone.',
  'menu.tooltip.SCHEDULE': 'Harmonogram',
  'print.FOOTER_MESSAGE': 'Printed by {fullName}({email}) on {currentDate}.',
  'print.WATERMARK': 'Copyright © {year} Pearson Education',
  'product.WELCOME': 'Witaj {userName}',
  'device.SWAPPED_ALREADY': 'W tym miesiącu już dokonałeś zamiany',
  'device.SWITCH_DEVICE': 'Przełączyć się na bieżące urządzenie?',
  'device.SWITCH_ONCE': 'Ponieważ możesz się przełączyć tylko raz w miesiącu, nie będziesz mógł się cofnąć.',
  'device.SEAT_TAKEN': 'To miejsce jest zajęte',
  'device.DISCONNECT_DEVICE': 'Możesz zalogować się tylko na 3 urządzeniach. Aby kontynuować, odłącz kolejne urządzenie.',
  'device.ALERT_TEXT': 'Pamiętaj, że możesz zmieniać urządzenia tylko raz w miesiącu.',
  'device.YOUR_MOJO': 'Jesteś na drodze do mojo',
  'device.DISCONNECT': 'Rozłącz',
  'device.CONTINUE': 'Kontynuuj',
  'device.HEADER': 'End your other browser session?',
  'device.CLOSE': 'Powrót do strony głównej',
  'device.CODE': 'Continue here by entering the authentication code we sent to {maskedEmail}. Not working? Check your spam folder, {email}, or contact {support}.',
  'device.TIMER': 'Masz {time}, aby wprowadzić swój kod tutaj',
  'device.RESEND': '{resend}',
  'device.FAQ': 'FAQ',
  'device.RESEND_NAME': 'Resend',
  'device.NOTE': 'If you regularly use Pearson+ in 2 places, like at work and at home, make sure to log out whenever you’re done. You can also use the Pearson+ mobile app on up to 2 devices. For more info, see our {faq}.',
  'device.NOTE_HEADER': 'Avoid this next time',
  'device.CONTACT_SUPPORT': '',
  'device.CONTACT_EMAIL': '',
  'device.CONTACT_FAQ': '',
  'device.INCORRECT': 'Nieprawidłowy - sprawdź ponownie swój kod lub wyślij go ponownie.',
  'device.MANY_ATTEMPTS': 'Zbyt wiele prób, spróbuj wysłać kod ponownie',
  'device.EXPIRED': 'Czas minął, spróbuj wysłać kod ponownie',
  'device.ACCESS_HEADER': 'Jesteś zalogowany!',
  'device.ACCESS_DESC': 'Twoja sesja została uwierzytelniona. Chcesz w tym czasie zaktualizować swoje hasło?',
  'device.ACCESS_CHANGE_PASSWORD': 'Zmień hasło',
  'device.ACCESS_RESET_PASSWORD': 'Zresetuj hasło',
  'device.THREATONE_TITLE': 'Wszystko OK?',
  'device.THREATONE_DESC': 'Zauważyliśmy podejrzaną aktywność na Twoim koncie.',
  'device.THREATONE_DESC_TWO': 'To dobry moment na zmianę hasła.',
  'device.MAY_BE_LATER': 'Może później',
  'device.OKAY': 'OK',
  'device.ACCOUNT_LOCK_DESC': 'Aby zapewnić bezpieczeństwo, wylogowaliśmy Cię. Wysłaliśmy link resetujący hasło na adres e-mail przypisany do Twojego konta.',
  'device.ACCOUNT_LOCK_TITLE': 'Zauważyliśmy podejrzaną aktywność na Twoim koncie',
  'device.ACCOUNT_LOCK_TIMER': 'Zostaniesz wylogowany za {time}',
  'menu.myAccount': 'Moje konto',
  'menu.helpCenter': 'Centrum pomocy',
  'menu.preferencesCenter': 'Centrum preferencji',
  'menu.HELP_SUPPORT': 'Pomoc i wsparcie',
  'menu.FAQS': 'Często zadawane pytania',
  'menu.CHAT_WITH_SUPPORT': 'Czat z pomocą techniczną',
  'menu.QUICK_WALKTHROUGH': 'Szybki przegląd',
  'menu.LEGAL': 'Prawne',
  'menu.PRIVACY_NOTICE': 'Informacja o prywatności',
  'menu.TITLE_INFO': 'Informacja tytułowa',
  'menu.PEARSON_ACCESSIBILITY': 'Dostępność Pearson',
  'menu.DEVICE_WARNING_MESSAGE': 'Zarejestruj {deviceLimit} urządzenia. Wymieniaj 1 raz w miesiącu.',
  'menu.ACCESSIBILITY': 'Dostępność',
  'menu.ACCESSIBILITY_SUPPORT': 'Wsparcie dostępności',
  'menu.DEVICES': 'Ostatnie urządzenia',
  'menu.THIS_DEVICE': 'To urządzenie',
  'menu.MOJO_APP_INFO': 'Aby korzystać z Pearson+ w trybie offline, przejdź do sklepu z aplikacjami na swoim telefonie lub tablecie.',
  'menu.DEVICE_LABEL': 'Urządzenie',
  'navigation.PAGE': 'Strona',
  'launchcode.CREATEBY': 'Utworzony przez:',
  'launchcode.sentvia': 'Wysłany za pomocą:',
  'launchcode.CODEINLAST': 'Kody z ostatnich 7 dni:',
  'launchcode.TIMESENT': 'Godzina wysyłki:',
  'launchcode.ACCOUNTEMAIL': 'Adres e-mail klienta:',
  'launchcode.LAUNCHDIALOGTITLE': 'Dziennik kodów OTP',
  'menu.OTP_CODES': 'Kody OTP',
  'menu.LAUNCH_CODE': 'Uruchom dziennik kodów',
  'menu.SEND_CODE': 'Wyślij nowy kod użytkownika przez e-mail',
  'menu.SEND_CODE_PHONE': 'Wyślij nowy kod użytkownika przez wiadomość SMS',
  'menu.COMMUNITY_GUIDELINES': 'Wytyczne dla społeczności',
  'textmessage.TITLE': 'Wprowadź numer telefonu',
  'textmessage.PHONE_NUMBER': 'Numer telefonu',
  'textmessage.SEND_CODE': 'Wyślij kod',
  'textmessage.INVALID_NUMBER': 'Nieprawidłowy numer telefonu komórkowego.',
  'textmessage.SUCCESS_MESSAGE': 'Kod został wysłany',
  'search.searchPlaceHolderPPlus': 'Search by ISBN, Title, or Author',
  'search.searchPlaceHolderEText': 'Wyszukaj swoją listę',
  'remove.title': 'Usunąć tytuł?',
  'remove.subTitle': 'Zawsze możesz odzyskać go później - wystarczy użyć wyszukiwania, aby ponownie go dodać.',
  'remove.buttonText': 'Usuń',
  'footer.downloadMobileApp': 'Pobierz aplikację mobilną',
  'footer.termsOfUse': 'Warunki korzystania',
  'footer.privacy': 'Prywatność',
  'footer.cookies': 'Cookies',
  'footer.doNotSellMyPersonalInformation': 'Nie sprzedawaj moich danych osobowych',
  'footer.accessibility': 'Dostępność',
  'footer.patentNotice': 'Informacja o patencie',
  'footer.copyrights': 'Pearson Wszystkie prawa zastrzeżone.',
  'channel.videos': 'Pliki wideo',
  'channel.questions': 'Pytania',
  'channel.descriptionA': 'Uzupełnij swoją lekturę o kanały.',
  'channel.descriptionB': 'Uzyskaj pomoc w trudnych tematach dzięki kanałom.',
  'channel.openchannel': 'Otwórz kanał',
  'hero.continueReading': 'Kontynuuj czytanie',
  'hero.openBook': 'Otwórz książkę',
  'hero.channel.open': 'Otwórz kanał',
  'hero.addATitle': 'Dodaj tytuł',
  'hero.toc.defaultPlacehoder': '--- select a topic ---',
  'hero.viewInactiveTitles': 'Zobacz nieaktywne tytuły',
  'library.marketingCard': {
    title: 'Choose from over 2,000 eTextbooks',
    subTitle: 'with audio, flashcards, notes, and more.',
    primaryButton: {
      text: 'Znajdź swój podręcznik eText'
    },
    secondaryButton: {
      text: 'View all titles'
    }
  },
  'hero.mappedChannelV2': {
    ariaLabel: 'channel',
    heading: 'Recommended study help Channel',
    callForAction: {
      text: 'Open'
    }
  },
  'hero.openEtextBook': 'Otwórz podręcznik eText',
  'hero.openEtextBookV1': 'Otwórz podręcznik eText',
  'hero.heroHeading': 'Poniewać uczysz się <span>{channel_title}</span>',
  'hero.heroHeadingForBundleUser': 'Pakiet do nauki i przygotowania do egzaminu',
  'hero.study.help': 'Uzyskaj pomoc w nauce',
  'hero.study.jumpBackIn': 'Wróć do gry',
  'hero.study.numOfUsers': 'Obecnie z kanałów korzysta <b>{num_of_users}</b> studentów.',
  'hero.heroBook.sectionHeading': 'Pozbądź się blokad związanych z kursem',
  'hero.heroBook.channelsSectionHeading': 'Rozpocznij nową sesję nauki',
  'hero.heroBook.sectionHeadingNewUser': 'Pomoc w nauce znajdziesz tutaj',
  'hero.heroBook.sectionSubHeading': 'Rozwiąż trudne tematy za pomocą pytań praktycznych i objaśnień wideo dostosowanych do Twojego kursu.',
  'hero.heroChannel.cardsHeading': 'Kontynuuj tam, gdzie przerwałeś',
  'hero.marketingcard.subheading': 'Ucz się wydajniej dzięki filmom, które wyjaśniają trudne koncepcje i dodatkowym ćwiczeniom, dostosowanym do Twojego kursu.',
  'hero.marketingcard1.copy.title': 'Ucz się z pomocą filmów',
  'hero.marketingcard1.copy.description': 'Obejrzyj objaśnienia wideo, które wyjaśniają trudne tematy.',
  'hero.marketingcard2.copy.title': 'Problemy w praktyce',
  'hero.marketingcard2.copy.description': 'Przygotuj się do egzaminu z tysiącami pytań egzaminacyjnych i rozwiązań wideo',
  'hero.marketingcard3.copy.title': 'Uzyskaj doświadczoną pomoc',
  'hero.marketingcard3.copy.description': 'Zadawaj pytania i otrzymuj odpowiedzi od ekspertów w danej dziedzinie.',
  'hero.popularTopics.heading': 'Inne popularne tematy na tym kanale',
  'hero.popularChannels.heading': 'Popularne kanały',
  'hero.popularChannels.headingv1': 'Bierzesz udział w kolejnym kursie?',
  'hero.popularChannels.description': 'Uzyskaj pomoc w nauce dzięki najlepszym kanałom.',
  'hero.popularChannels.descriptionv1': 'Uzyskaj pomoc w innych kursach, wybierając odpowiedni kanał.',
  'hero.popularChannels.descriptionv2': 'Przeglądaj popularne tematy pomocy w nauce.',
  'hero.startReading': 'Rozpocznij czytanie',
  'hero.practice': 'Przećwicz',
  'hero.noTitleNoChannel.sectionHeading': 'Przygotuj się do egzaminu w rekordowym czasie',
  'hero.herobook.mappedChannelHeading': 'Polecana pomoc w nauce na tym kanale',
  'libraryL2.myBooks': 'My Books',
  'libraryL2.archived': 'Archived',
  'hero.heroHeadingNoMappedChannel': 'Based on what you\'re studying',
  'hero.practiceProblem': 'Practice Problem',
  'nativeAppBanner.drawer.title': 'Open in ...',
  'nativeAppBanner.drawer.pearsonApp': 'Pearson+ App',
  'nativeAppBanner.banner.title': 'Pearson+ app',
  'nativeAppBanner.banner.description': 'Learn on the go',
  open: 'Open',
  continue: 'Continue',
  'hero.promotionalcard.list1': 'Krótkie filmy, które wyjaśniają trudne tematy',
  'hero.promotionalcard.list2': 'Tysiące pytań egzaminacyjnych',
  'hero.promotionalcard.list3': 'Otrzymuj odpowiedzi od ekspertów w danej dziedzinie',
  'hero.studyandexamprep': 'Nauka i przygotowanie do egzaminu',
  'hero.SeeAllStudyOptions': 'See all study options',
  'hero.tocLabel': 'View content related to your eTextbook',
  'hero.herobook.mappedChannelHeadingV1': 'Recommended videos and practice questions',
  'hero.popularTopics.headingV1': 'Other popular topics',
  'hero.heroHeadingV1': 'For <span>{replace_value}</span>',
  'hero.learnmore': 'Dowiedz się więcej',
  'hero.subscriptionStatusTitle': 'Status subskrypcji Pearson+',
  'hero.eTextbook': 'Podręcznik eText',
  'hero.mylabOrMastering': 'MyLab or Mastering',
  'hero.goToPage.label': 'Przejdź do strony',
  'subscription.eTextbook': 'Podręcznik eText',
  'subscription.mylab': 'MyLab or Mastering',
  'subscription.studyBundle': 'Pakiet do nauki i przygotowania do egzaminu',
  'subscription.studyNoBundle': 'Nauka i przygotowanie do egzaminu',
  'subscription.tryItOut': 'Try it out',
  'print.printAvailTitle': 'Chcesz wydrukować tę stronę? Oto jak',
  'print.printAvailDesc': 'W eTexbook wybierz ikonę konta w prawym górnym rogu. W opcjach wybierz „Drukuj stronę”, a następnie wybierz ustawienia drukowania.',
  'print.printNotAvailTitle': 'The print option for this eText is not enabled.',
  'print.printNotAvailDesc': 'For convenient offline access, please download your title through the Pearson+ mobile app and enjoy the flexibility of studying anytime, anywhere!',
  'hero.header.cta': '{hero_header_cta_text} study tools',
  'hero.marketing.title': 'Znajdź swój podręcznik eText',
  'hero.marketing.subTitle': 'Choose from over 2,000 eTextbooks with audio, flashcards, notes, and more. Only $10.99/mo for a 4-month term.',
  'hero.allyourcourses': 'all your courses',
  'hero.popularchannels.desc': 'Get study help with these top subjects',
  'hero.removecourse.title': 'Usunąć kurs z pulpitu nawigacyjnego?',
  'hero.removecourse.description': 'Zawsze możesz dodać ten kurs z powrotem do pulpitu nawigacyjnego, klikając „Dodaj kurs”',
  'hero.removecourse.primaryCtaText': 'Usuń',
  'hero.removecourse.secondaryCtaText': 'Anuluj',
  'hero.subscriptionStatusTitle1': 'What\'s Available for you in Pearson+',
  'hero.emptystate.cta': 'Wyszukaj elementy eTextbook',
  'hero.watch_it_again': 'Watch it again',
  'hero.mondly.learnLang.title': 'Naucz się nowego języka za darmo',
  'hero.mondly.learnLang.title2': 'Naucz się nowego języka za darmo!',
  'hero.mondly.subTitle': 'Użytkownicy eTextbook Pearson otrzymują trzy bezpłatne miesiące Mondly by Pearson.',
  'hero.mondly.description': 'Większość aplikacji do nauki języków obcych pozwala uczyć się tylko z poziomu języka angielskiego. Najlepiej jest jednak uczyć się w swoim ojczystym języku, więc Mondly umożliwia naukę w dowolnym z 41 języków.',
  'hero.mondly.startLearningCTA': 'Rozpocznij naukę',
  'hero.mondly.marketing.bestApp': 'Best App',
  'hero.mondly.marketing.appStore': 'App Store',
  'hero.mondly.marketing.appYear': 'App of the Year',
  'hero.mondly.marketing.fbStart': 'Facebook FbStart - 2017 EMEA Winner',
  'hero.mondly.marketing.editorChoice': 'Editor Choice',
  'hero.mondly.marketing.googlePlay': 'Google Play',
  'header.menu.moreAtPearson': 'Więcej na stronie Pearson',
  'hero.extractingExamDetails': 'Wyodrębnianie szczegółów egzaminu',
  'hero.finishingTheProcess': 'Kończenie procesu',
  'hero.hereWhatWeGotFromYourSyllabus': 'Oto, czego dowiedzieliśmy się z Twojego sylabusa',
  'hero.school': 'Szkoła:',
  'hero.class': 'Klasa:',
  'hero.textbook': 'Podręcznik:',
  'hero.examSchedule': 'Harmonogram egzaminów:',
  'hero.confirm': 'Potwierdź',
  'onboard.aboutCourse': 'Opowiedz nam o swoim kursie',
  'onboard.studyMaterials': 'Wybierz swój przedmiot, a my stworzymy pulpit nawigacyjny ze spersonalizowanymi materiałami do nauki.',
  'hero.personalizedStudyPlan': 'Wykorzystamy sztuczną inteligencję, aby zapewnić Ci spersonalizowany plan nauki.',
  'hero.welcomeToP': 'Witamy w P+!',
  'hero.learnYourWayAround': 'Poświęć teraz chwilę, aby nauczyć się poruszać po pulpicie lub wybierz inny termin w ustawieniach konta.',
  'hero.weveCreatedADashboard': 'Tutaj stworzyliśmy pulpit nawigacyjny dla Twojego kursu.',
  'hero.youCanAddYourPearsonCourse': 'Możesz także dodać własne kursy spoza platformy Pearson i wypróbować materiały do nauki za darmo.',
  'libraryL2.back': 'Wróć',
  'hero.getExamreadyWithVideoLessons': 'Przygotuj się do egzaminu dzięki lekcjom wideo i zestawom ćwiczeń dostosowanym do Twojego kursu.',
  'hero.youreTexbook': 'Twój eTexbook i wszystkie powiązane narzędzia znajdują się tutaj.',
  'hero.VideosAndPractice': 'W sekcji nauki i przygotowania do egzaminu zawsze znajdziesz więcej filmów i praktycznych pytań.',
  'hero.pleaseSelectTheChapters': 'Wybierz rozdziały związane z tym egzaminem:',
  'hero.tableOfContents': 'Spis treści',
  'hero.search': 'Wyszukaj',
  'hero.notes': 'Uwagi i najważniejsze informacje',
  'hero.doYouWantToAddACourse': 'Czy chcesz dodać kurs?',
  'hero.whatsAvailableForYou': 'Co jest dostępne dla Ciebie',
  'hero.whatsAvailableForYouInPearson': 'Co jest dla Ciebie dostępne w Pearson+?',
  'hero.continueStudying': 'Kontynuuj naukę',
  'hero.startStudying': 'Rozpocznij naukę',
  'hero.unlockYourStudyExamPrep': 'Już teraz uzyskaj dostęp do pakietu do nauki i przygotowania do egzaminu dla innych kursów!',
  'header.menu.learnLanguage': 'Naucz się języka',
  'hero.subscriptionStatusTitle2': 'Twoje zasoby Pearson+',
  'hero.getExamreadyForYou': 'Przygotuj się do egzaminów z innych kursów, korzystając z funkcji dostępu do nauki i przygotowania do egzaminu.',
  'hero.weVideoLessons': 'Oferujemy lekcje wideo i zestawy ćwiczeń dostosowane do Twoich potrzeb.',
  'hero.tryForFree': 'Wypróbuj za darmo',
  'hero.heroBook.sectionSubHeadingV1': 'Rozwiąż trudne tematy za pomocą pytań praktycznych i objaśnień wideo dostosowanych do Twojego kursu.',
  'hero.heroBook.sectionSubHeadingV2': 'Break down tough topics with concept explanations and practice questions tailored to your course.',
  'hero.addCourseUsingStudyExamPrep': 'Dodaj kurs za pomocą funkcji dostępu do nauki i przygotowania do egzaminu',
  'hero.for': 'Dla',
  'hero.studyExamPrepPackForAllCourse': 'Pakiet do nauki i przygotowania do egzaminu dla wszystkich Twoich kursów',
  'hero.myETextbooks': 'Moje podręczniki eText',
  'header.continueInTheApp': 'Kontynuuj w aplikacji',
  'hero.goToMyLabMastering': 'Przejdź do MyLab/Mastering',
  'hero.learnTxt': 'Ucz się',
  'hero.aITutorTxt': 'Nauczyciel AI',
  'hero.exploreTxt': 'Poznaj',
  'hero.studyExamPrepAllYourCourses': 'Nauka i przygotowanie do egzaminu dla wszystkich Twoich kursów',
  'hero.examSchedules': 'Harmonogram egzaminów',
  'hero.examSchedules2': 'Harmonogram egzaminów',
  'hero.uploadYourSyllabus': 'Prześlij swój sylabus',
  'hero.addAnExam': 'Dodaj egzamin',
  'hero.UploadYourSyllabus.studyExamPrepPack': 'Prześlij swój sylabus, aby w pełni wykorzystać swój pakiet do nauki i przygotowania do egzaminu',
  'hero.UploadYourSyllabus.studyExamPrep': 'Prześlij swój sylabus, aby w pełni wykorzystać swój pakiet do nauki i przygotowania do egzaminu',
  'hero.getRecommendationsOnWhat': 'Uzyskaj rekomendacje dotyczące tego, czego i kiedy się uczyć',
  'hero.noSyllabusSharingExamSchedule': 'Nie masz sylabusa? Działa również udostępnianie harmonogramu egzaminów',
  'hero.selectDropSyllabusFile': 'Wybierz lub upuść plik sylabusa w formacie PDF lub DOCX',
  'hero.uploadTxt': 'Prześlij',
  'hero.examName': 'Nazwa egzaminu',
  'hero.completeTxt': 'Wypełnij',
  'hero.exactTopicsChapter': 'Później będziesz mógł określić dokładne tematy dla każdego rozdziału.',
  'hero.uploadingDocument': 'Przesyłanie dokumentu',
  'hero.activatingAIComponents': 'Aktywacja komponentów sztucznej inteligencji',
  'hero.extractingClassSchoolData': 'Wyodrębnianie danych klasy i szkoły',
  'hero.extractingTextbookChapters': 'Wyodrębnianie rozdziałów podręcznika',
  'hero.examPrepTxt': 'Przygotowanie do egzaminu',
  'hero.extractingDataFromSyllabus': 'Wyodrębnianie danych z sylabusa...',
  'hero.myLabMastering': 'MyLab/Mastering',
  'hero.dateFormat': 'DD/MM/YY',
  'header.qrCode.subHeaderTxt': 'Użyj tego kodu QR, aby szybko i bezpiecznie zalogować się do aplikacji Pearson+ na urządzeniu mobilnym.',
  'header.qrCode.dontHaveApp': 'Nie masz aplikacji?',
  'header.qrCode.footerTxt': 'Zeskanowanie tego kodu spowoduje przekierowanie do sklepu App Store lub Google Play. Po pobraniu aplikacji zaloguj się, skanując ponownie kod.',
  'header.qrCode.errorMsg': 'Kod QR nie został załadowany. Zamknij i spróbuj ponownie',
  'hero.yourETextbook': 'Twój podręcznik eText',
  'onboard.addCourseWithEtextBundle': 'Dodaj kurs za pomocą funkcji dostępu do nauki i przygotowania do egzaminu pakiet',
  'onboard.disableTooltipText': 'All Study & Exam Prep courses are added!',
  'subscriptionstatus.bundlecta': 'Access Study & Exam Prep Pack',
  'subscriptionstatus.nonbundlecta': 'Uzyskaj dostęp do nauki i przygotowania do egzaminu',
  'channel.genericBundle': 'Wykorzystaj swój pakiet dostępu do nauki i przygotowania do egzaminu dla potrzeb innych kursów',
  'channel.relatedBundle': 'Wykorzystaj również swój pakiet dostępu do nauki i przygotowania do egzaminu dla potrzeb innych kursów',
  'onboard.courseAdded': 'Kurs dodany!',
  'hero.flashcards': 'Fiszki',
  'aichat.title': 'AI Study Tool',
  'aichat.placeholder': 'Which topic or concept from your eTextbook can I explain for you?',
  'aichat.charDesc': 'Ask me anything related to <b>%BOOK_TITLE%</b>.',
  'relatedChannelCard.title': 'Enhance your learning with this related resource',
  'relatedChannelCard.badgeText': 'Related Study & Exam Prep',
  'sessionInactivity.title': 'Are you still there?',
  'sessionInactivity.description': 'Your session will time out in <b>%TIMER%</b> due to inactivity.<br/>Would you like to stay signed in?',
  'sessionInactivity.keepMeSignIn': 'Keep me signed in',
  'sessionInactivity.signOut': 'Sign Out',
  'sessionTimeout.title': 'Session timed out',
  'sessionTimeout.description': 'Your session has timed out due to inactivity.',
  'print.qrCode.header': 'Użyj tego kodu QR, aby szybko i bezpiecznie otworzyć aplikację Pearson+ na urządzeniu mobilnym.',
  'print.qrCode.footer': 'Zeskanowanie tego kodu spowoduje przekierowanie do sklepu App Store lub Google Play. Po zainstalowaniu aplikacji zaloguj się, aby pobrać i uzyskać dostęp do swojego tytułu.'
};

export default pl;
