/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * React component for Device Management Popup
 *
 * @file DeviceManagement.jsx
 * @author Infant Diana Sebastian
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    useTheme,
    withStyles,
    Button,
    Link
} from '@material-ui/core';
import Framework from '@greenville/framework';
import DeviceOtpInput from './DeviceOtpInput';
import env from '../env';
import * as constants from '../constants';
import CommonUtils from '../utils/CommonUtils';

const styles = () => ({
    dialogPaper: {
        background: '#F7F9FD',
        backgroundImage: 'url(/device_bg.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'left'
    },
    dialogaction: {
        margin: '8px 8px 0 0'
    },
    cancelButton: {
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: '150%',
        color: '#4F5561'
    },
    dialogTitle: {
        fontFamily: 'TT Commons',
        fontSize: 28,
        lineHeight: '110%',
        color: '#05112A',
        fontWeight: 'bold',
        marginLeft: 240,
        paddingBottom: 0
    },
    dialogcontent: {
        marginLeft: 240,
        '& $dialogTitle':{
            marginLeft: 0
        }
    },
    code: {
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '150%',
        color: '#333333',
        marginBottom: 36,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    timer: {
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 14,
        lineHeight: '120%',
        color: '#333333',
        marginBottom: 8
    },
    resend: {
        marginTop: 5,
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: '120%',
        color: '#333333'
    },
    contact: {
        marginTop: 12,
        fontFamily: 'Hind',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: '120%',
        color: '#333333',
        marginBottom: 10
    },
    dialogContainer: {
        background: 'radial-gradient(66.26% 58.26% at 0% 42%, rgb(246 252 253 / 0%) 45.62%, #F7F9FD 100%)',
        backgroundBlendMode: 'multiply',
        mixBlendMode: 'normal'
    },
    link: {
        fontSize: 14,
        color: '#6D0176',
        fontWeight: 'bold',
        '&:hover': {
            color: '#54005D'
        },
        cursor: 'pointer'
    }
});
const DeviceManagement = ({ classes, dialogOpen, onClose }) => {
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    const language = Framework.getStoreRegistry().getStore('language');
    const device = Framework.getStoreRegistry().getStore('device');
    const deviceId = window.piSession && window.piSession.getDeviceId();
    const [otp, setOtp] = useState('');
    const [errorType, setErrorType] = useState('');
    const [attempts, setAttempts] = useState(0);
    const initialMinute = 15, initialSeconds = 0;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [otpValid, setOtpValid] = useState(false);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                    setErrorType(constants.DEVICE_EXPIRED);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        if (errorType && !otpValid) {
            switch (errorType) {
                case constants.DEVICE_EXPIRED:
                    CommonUtils.deviceManagementOTPEvents(constants.SUBMIT_CODE, constants.CON_SESSION_MODAL, constants.SUBMIT_MFA_CODE, constants.OTP_EXPIRED);
                    break;
                case constants.DEVICE_INCORRECT:
                    CommonUtils.deviceManagementOTPEvents(constants.SUBMIT_CODE, constants.CON_SESSION_MODAL, constants.SUBMIT_MFA_CODE, constants.INVALID);
                    break;
                case constants.DEVICE_MANY_ATTEMPTS:
                    CommonUtils.deviceManagementOTPEvents(constants.SUBMIT_CODE, constants.CON_SESSION_MODAL, constants.SUBMIT_MFA_CODE, constants.TOO_MANY_ATTEMPTS);
                    break;
            }
        }
        if (!errorType && otpValid) {
            CommonUtils.deviceManagementOTPEvents(constants.SUBMIT_CODE, constants.CON_SESSION_MODAL, constants.SUBMIT_MFA_CODE, constants.VALID);
        }
    }, [errorType, otpValid]);

    const handleOtpChange = (otp) => {
        const clipBoardData = window.event.clipboardData && window.event.clipboardData.getData('text');
        const regExp = /[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
        if (clipBoardData && regExp.test(clipBoardData.toString())) {
            setOtp('');
            return false; 
        }

        if (otp.length === 6) {
            Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
                type: 'validateOTP',
                data: {
                    ticket: otp,
                    deviceId
                }
            });
            Framework.getEventManager().on(constants.DEVICE_OTP_VALIDATE_SUCCESS, (data) => {
                setOtpValid(true);
                onClose();
            });
            Framework.getEventManager().on(constants.DEVICE_OTP_VALIDATE_FAILURE, (data) => {
                setOtpValid(false);
                setErrorType(constants.DEVICE_INCORRECT);
                setAttempts(attempts + 1);
            });
        }
        else {
            setErrorType('');
        }
        if (attempts === 5) {
            setErrorType(constants.DEVICE_MANY_ATTEMPTS);
        }
        setOtp(otp);
    };
    const maskEmail = (email = '') => {
        return (email.indexOf('@') <= 4 ? email.slice(0, 1) : email.slice(0, 4)) + '*****' + email.slice(email.indexOf('@'), email.length);
    };
    const handleClose = () => {
        CommonUtils.deviceManagementOTPEvents(constants.OTP_LINK_MODAL, constants.OTP_MODAL, constants.CLICK_LINK, constants.BACK_TO_HOME);
        window.location.href = `${env.CONTENT_HOST}/home`;
    }
    const handleLink = (url, linkText, target) => {
        CommonUtils.deviceManagementOTPEvents(constants.OTP_LINK_MODAL, constants.OTP_MODAL, constants.CLICK_LINK, linkText);
        window.open(url, target)
    }
    const handleOtpResend = () => {
        Framework.getEventManager().publish(constants.IES_DEVICE_LIST_REQUESTED, {
            type: 'generateOTP',
            data: {
                clientId: env.CLIENT_ID,
                deviceId
            }
        });
        setOtp('');
        setErrorType('');
        setMinutes(15);
        setSeconds(0);
        setAttempts(0);
        CommonUtils.deviceManagementReSentOTPEvents(constants.RESEND_OTP);
        CommonUtils.dispatchResendGaEvent(constants.CONCURRENT_SESSIONS_MODAL_EVENT,
            constants.CONCURRENT_SESSIONS_EVENT_NAME, constants.CLICK_RESEND_MFA);
    };
    return (
        <React.Fragment>
            <Dialog
                open={dialogOpen}
                id="device-management-dialog"
                fullScreen={fullScreen}
                classes={{ paper: classes.dialogPaper }}
                aria-labelledby="device-management-dialog-title"
                aria-describedby="device-management-dialog-description"
                role="dialog"
                aria-modal="true"
                className="dialog-zindex"
            >
                <div className={classes.dialogContainer}>
                    <DialogActions classes={{ root: classes.dialogaction }}>
                        <Button
                            className={classes.cancelButton}
                            id="back-home"
                            color="default"
                            onClick={handleClose}
                            size="medium"
                        >
                            <FormattedMessage {...language.getText('device.CLOSE')} />
                        </Button>
                    </DialogActions>
                    <DialogTitle
                        disableTypography
                        id="device-management-dialog-title"
                        classes={{ root: classes.dialogTitle }}
                    >
                        <FormattedMessage {...language.getText('device.HEADER')} />
                    </DialogTitle>
                    <DialogContent id="device-management-dialog-description" classes={{ root: classes.dialogcontent }}>
                        {device.primaryEmail && <Typography classes={{ root: classes.code }}>
                            <FormattedMessage
                                {...language.getText('device.CODE')}
                                values={
                                    {
                                    maskedEmail: maskEmail(device.primaryEmail),
                                    support: (
                                        <Link
                                            onClick={() => { handleLink(`${env.CONTENT_HOST}/home?openChat=true`, constants.CONTACT_CUST_SUPPORT, '_self') }}
                                            className={classes.link}
                                        >
                                            <FormattedMessage
                                                {...language.getText('device.CONTACT_SUPPORT')}
                                            />
                                        </Link>
                                    ),
                                    email: (
                                        <Link
                                            onClick={() => { handleLink(env.BOOKSHELF_CONFIG.updateProfile, constants.UPDATE_EMAIL, '_self') }}
                                            className={classes.link}
                                        >
                                            <FormattedMessage
                                                {...language.getText('device.CONTACT_EMAIL')}
                                            />
                                        </Link>
                                    )
                                }
                             }
                            />
                        </Typography>}
                        <Typography
                            classes={{ root: classes.timer }}
                            style={errorType.length > 0 ? { color: '#7E031A', fontWeight: 'normal' } : null}
                        >
                            {errorType.length > 0 ?
                                <FormattedMessage
                                    {...language.getText(errorType === constants.DEVICE_INCORRECT ? 'device.INCORRECT' :
                                        errorType === constants.DEVICE_MANY_ATTEMPTS ? 'device.MANY_ATTEMPTS' : 'device.EXPIRED')}
                                /> :
                                <FormattedMessage
                                    {...language.getText('device.TIMER')}
                                    values={
                                        {
                                            time:
                                            <span
                                                style={{
                                                    width: minutes > 9 ? '30px' : '25px',
                                                    display: 'inline-block'
                                                }}
                                            >
                                                {minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </span>
                                        }
                                    }
                                />
                            }
                        </Typography>
                        <DeviceOtpInput
                            otp={otp}
                            onChange={handleOtpChange}
                            isError={errorType.length > 0}
                            isDisabled={errorType === constants.DEVICE_MANY_ATTEMPTS || errorType === constants.DEVICE_EXPIRED}
                        />
                        <Typography classes={{ root: classes.resend }}>
                            <FormattedMessage
                                {...language.getText('device.RESEND')}
                                values={
                                    {
                                        resend: (
                                            <Link
                                                target="_blank"
                                                className={classes.link}
                                                onClick={handleOtpResend}
                                            >
                                                <FormattedMessage
                                                    {...language.getText('device.RESEND_NAME')}
                                                />
                                            </Link>
                                        )
                                    }
                                }
                            />
                        </Typography>
                    </DialogContent>
                    <DialogContent id="device-management-dialog-description" classes={{ root: classes.dialogcontent }}>
                        <Typography classes={{ root: classes.dialogTitle }}>
                            <FormattedMessage
                                {...language.getText('device.NOTE_HEADER')}
                            />
                        </Typography>
                        <Typography classes={{ root: classes.contact }}>
                            <FormattedMessage
                                {...language.getText('device.NOTE')}
                                values={{
                                    faq: (
                                        <Link
                                            onClick={() => { handleLink(env.BOOKSHELF_CONFIG.faq, constants.PEARSON_FAQ, '_blank') }}
                                            className={classes.link}
                                        >
                                            <FormattedMessage
                                                {...language.getText('device.FAQ')}
                                            />
                                        </Link>
                                    )
                                }}
                            />
                        </Typography>
                    </DialogContent>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

DeviceManagement.propTypes = {
    classes: PropTypes.object.isRequired,
    dialogOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(DeviceManagement);
