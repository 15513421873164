/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Notification Banner
 *
 * @file NotificationBanner.jsx
 * @author Mohamed yasar arabath M
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ReactHtmlParser from 'react-html-parser';
import NotificationCloseIcon from '../../../icons/NotificationCloseIcon';
import NotificationBannerStyles from './styles/NotificationBannerStyles';

const NotificationBanner = ({ styles, message, onClose }) => {
  const classes = NotificationBannerStyles(styles)();

  return (
    <Alert
      icon={false}
      classes={{ root: classes.root, message: classes.message, action: classes.action }}
      action={(
        <IconButton aria-label="close" onClick={onClose}>
          <NotificationCloseIcon />
        </IconButton>
)}
    >
      {ReactHtmlParser(message)}
    </Alert>
  );
};

NotificationBanner.defaultProps = {
  onClose: () => {},
  styles: {}
};

NotificationBanner.propTypes = {
  styles: PropTypes.object,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default memo(NotificationBanner);
