/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
*  Copyright © 2024 Pearson Education, Inc.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Pearson Education, Inc.  The intellectual and technical concepts contained
* herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
* patent applications, and are protected by trade secret or copyright law.
* Dissemination of this information, reproduction of this material, and copying or distribution of this software
* is strictly forbidden unless prior written permission is obtained
* from Pearson Education, Inc.
* */

/**
 * QR code popup style
 *
 * @file QRCodePrintMessageStyles.jsx
 * @author Ujjwal Sana
 * @since 0.0.1
 */

import mixins from '../../../../assets/styles/mixins.scss';

const QRCodePrintMessageStyles = () => ({
  dialogQRSubheader: {
    height: 48,
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize20,
    lineHeight: mixins.lineHeight24,
    textAlign: 'center',
    letterSpacing: '0.4px',
    color: mixins.darkThemeColor,
    wordBreak: 'break-word'
  },
  infodescriptionWrapper: {
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: 440,
    borderRadius: 16,
    border: `4px solid ${mixins.greyColor}`,
    background: mixins.whiteLightFont,
    padding: 24
  },
  qrImageContainer: {
    display: 'flex',
    padding: 32,
    gap: 32,
    borderRadius: 12,
    border: '2px solid #C7CCD6'
  },
  qrMsgContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  dialogQueMsg: {
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight700,
    fontSize: mixins.fontSize18,
    lineHeight: mixins.lineHeight24,
    textAlign: 'center',
    letterSpacing: '0.24px',
    color: mixins.darkThemeColor,
    wordBreak: 'break-word'
  },
  dialogFooterMsg: {
    fontFamily: mixins.fontFamilyTT,
    fontWeight: mixins.fontWeight400,
    fontSize: mixins.fontSize16,
    lineHeight: mixins.lineHeight20,
    textAlign: 'center',
    letterSpacing: '0.24px',
    color: mixins.darkThemeColor
  },
  dialogFooterAction: {
    display: 'flex',
    justifyContent: 'center',
    width: 432,
    height: 40,
    gap: 8
  }
});
export default QRCodePrintMessageStyles;
