/**
PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 *  Copyright © 2024 Pearson Education, Inc.
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
* */

/**
 * NotificationBannerStyles
 *
 * @file NotificationBannerStyles.jsx
 * @author Mohamed yasar arabath M
 */
import { makeStyles } from '@material-ui/core';
import mixins from '../../../../../assets/styles/mixins.scss';

const NotificationBannerStyles = ({ backgroundColor, fontColor, fontSize, fontWeight }) => makeStyles({
  root: {
    background: backgroundColor || mixins.swatchSecondary300,
    borderRadius: 8,
    padding: 8,
    justifyContent: 'center',
    marginBottom: 8,
    [mixins['lgl-min']]: {
      marginBottom: 0
    }
  },
  message: {
    color: fontColor || mixins.swatchSecondary900,
    fontFamily: mixins.fontFamilyTT,
    fontSize: fontSize || mixins.fontSize16,
    fontWeight: fontWeight || mixins.fontWeight400,
    lineHeight: mixins.lineHeight20,
    letterSpacing: '0.24px',
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    padding: '4px 0',
    '& a': {
      color: fontColor || mixins.swatchSecondary900
    }
  },
  action: {
    margin: 0,
    padding: 0,
    '& button': {
      color: mixins.swatchSecondary900,
      padding: 8
    }
  }
});
export default NotificationBannerStyles;
