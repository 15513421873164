/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Print Message styles
 *
 * @file PrintMessageStyles.jsx
 * @author Gladson Samuel S
 */

import mixins from '../../../../assets/styles/mixins.scss';

const PrintMessageStyles = () => ({
  container: {
    display: 'none',
    padding: 48,
    gap: 48,
    flexDirection: 'column',

    '@media print': {
      display: 'flex',
      visibility: 'visible'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  contentContianer: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    color: mixins.blackColor,
    fontSize: 32,
    fontFamily: 'TT Commons',
    fontWeight: 700,
    lineHeight: '32px'
  },
  description: {
    color: mixins.blackColor,
    fontSize: 18,
    fontFamily: 'TT Commons',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: 0.24
  },
  illustration: {
    width: 440,
    height: 606
  }
});

export default PrintMessageStyles;
